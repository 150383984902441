import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMagnifyingGlass, 
  faClipboardList, 
  faStar, 
  faCrown,
  faLeaf, 
  faSun, 
  faTree, 
  faSnowflake,
  faWrench,
  faScrewdriverWrench,
  faGear
} from '@fortawesome/free-solid-svg-icons';

import Navbar from '../components/Navbar';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { useCart } from '../context/CartContext';
import { ServicePackage, Addon, ServiceType } from '../types';
import { 
  validateServicePackage, 
  validateAddons, 
  validateServiceType 
} from '../utils/validation';
import { 
  PACKAGES, 
  SEASONAL_PACKAGES, 
  DIAGNOSTIC_PACKAGES,
  // MAINTENANCE_PACKAGES 
} from '../data/mockData';
import { useAnalytics } from '../hooks/useAnalytics';
import analyticsService from '../services/analytics/analyticsService';
import { trackUserEngagement } from '../services/analytics/enhancedAnalytics';
import { useScrollTracking } from '../hooks/useScrollTracking';
import { useClickTracking } from '../hooks/useClickTracking';

export default function BookingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    selectedVehicle, 
    setSelectedVehicle, 
    setPackage, 
    addOns,
    setAddOns 
  } = useCart();
  const analytics = useAnalytics(analyticsService);
  const trackClick = useClickTracking();
  useScrollTracking('booking-page');

  // Improved state management with explicit types
  const [step, setStep] = useState<number>(selectedVehicle ? 1 : 2);
  const [selectedServiceType, setSelectedServiceType] = useState<ServiceType>('pre-purchase');
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [selectedService, setSelectedService] = useState<{
    serviceTitle?: string;
    serviceType?: string;
    servicePrice?: string;
  }>({});
  const [selectedPackage, setSelectedPackage] = useState<ServicePackage | null>(null);

  useEffect(() => {
    // Extract service details from navigation state
    const state = location.state as { 
      serviceTitle?: string; 
      serviceType?: string; 
      servicePrice?: string;
      package?: ServicePackage;
      fromAddOns?: boolean;
      showBookingForm?: boolean;
    };
    
    if (state) {
      // If coming from AddOns page, show booking form
      if (state.fromAddOns && state.showBookingForm) {
        setStep(3); // Assuming 3 is the booking form step
      }
      
      // If a package was passed
      if (state.package) {
        setPackage(state.package);
        setSelectedServiceType(state.package.type);
        setSelectedPackage(state.package);
      }

      setSelectedService({
        serviceTitle: state.serviceTitle,
        serviceType: state.serviceType,
        servicePrice: state.servicePrice
      });
    }
  }, [location.state, setPackage, setSelectedServiceType]);

  useEffect(() => {
    analytics.trackEvent('page_view', {
      page: 'booking',
      step: step,
      service_type: selectedServiceType
    });
  }, [step, selectedServiceType, analytics]);

  // Memoized package selection function with type safety and validation
  const getPackagesByType = useCallback((type: ServiceType): ServicePackage[] => {
    switch (type) {
      case 'pre-purchase':
        return PACKAGES;
      case 'seasonal':
        return SEASONAL_PACKAGES;
      case 'diagnostic':
        return DIAGNOSTIC_PACKAGES;
      default:
        return [];
    }
  }, []);

  // Memoize current packages to prevent unnecessary re-renders
  const currentPackages = useMemo(() => 
    getPackagesByType(selectedServiceType), 
    [selectedServiceType, getPackagesByType]
  );

  // Improved service type change handler with validation
  const handleServiceTypeChange = useCallback((type: ServiceType) => {
    if (!validateServiceType(type)) {
      setValidationErrors(prev => ({
        ...prev,
        serviceType: 'Invalid service type selected'
      }));
      return;
    }
    
    // Clear previous errors
    setValidationErrors(prev => {
      const { serviceType, ...rest } = prev;
      return rest;
    });

    trackUserEngagement.serviceInteraction({
      name: type,
      category: 'service_type'
    }, 'select');

    setSelectedServiceType(type);
    setPackage(null);
    analytics.trackEvent('service_type_selected', {
      service_type: type,
      page: 'booking'
    });
  }, [setSelectedServiceType, setPackage, analytics]);

  // Improved package selection handler with validation
  const handlePackageSelect = useCallback((pkg: ServicePackage) => {
    // Optional click tracking
    if (trackClick) {
      trackClick({ target: pkg.name });
    }

    trackUserEngagement.serviceInteraction({
      name: pkg.name,
      price: pkg.price,
      category: 'package'
    }, 'select');

    setPackage(pkg);
    setSelectedPackage(pkg);
    trackUserEngagement.bookingStarted(pkg, location || 'not_specified');
    navigate('/addons');
    analytics.trackEvent('package_selected', {
      package_name: pkg.name,
      package_price: pkg.price,
      page: 'booking'
    });
  }, [setPackage, navigate, location, analytics]);

  // Keyboard accessibility for service type change
  const handleKeyboardServiceTypeChange = useCallback((type: ServiceType, event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleServiceTypeChange(type);
    }
  }, [handleServiceTypeChange]);

  // Handle form submission
  const handleBookingSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    
    // Track the booking completion
    analytics.trackEvent('booking_completed', {
      package_name: selectedPackage?.name,
      package_price: selectedPackage?.price,
    });

    // Navigate to payment page
    navigate('/payment', { 
      state: { 
        package: selectedPackage,
        fromBooking: true
      }
    });
  }, [navigate, selectedPackage, analytics]);

  // Render different content based on step
  const renderStepContent = () => {
    switch (step) {
      case 1:
        // Vehicle selection step
        return selectedVehicle ? (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Selected Vehicle</h2>
            <p>{selectedVehicle.make} {selectedVehicle.model} ({selectedVehicle.year})</p>
            <button 
              onClick={(e) => {
                if (trackClick) {
                  trackClick(e);
                }
                setStep(2);
              }} 
              className="mt-4 w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700"
            >
              Continue to Services
            </button>
          </div>
        ) : (
          renderStepContent()
        );
      
      case 2:
        // Service selection step
        return (
          <div>
            {/* Service Type Selection */}
            <div 
              className="bg-white rounded-lg shadow-sm p-6 mb-8" 
              role="region" 
              aria-label="Service Type Selection"
            >
              <h2 className="text-2xl font-semibold mb-6">Select Service Type</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {(['pre-purchase', 'seasonal', 'diagnostic'] as ServiceType[]).map(type => (
                  <button
                    key={type}
                    onClick={(e) => {
                      if (trackClick) {
                        trackClick(e);
                      }
                      handleServiceTypeChange(type);
                    }}
                    className={`
                      w-full p-4 border rounded-lg transition-all duration-200 
                      ${selectedServiceType === type 
                        ? 'border-blue-500 bg-blue-50 text-blue-700' 
                        : 'border-gray-200 hover:border-blue-300 hover:bg-blue-50'}
                    `}
                  >
                    {type === 'pre-purchase' && 'Pre-Purchase Inspection'}
                    {type === 'seasonal' && 'Seasonal Maintenance'}
                    {type === 'diagnostic' && 'Diagnostic Service'}
                  </button>
                ))}
              </div>
            </div>

            {/* Service Packages Grid */}
            <div className={`grid grid-cols-1 ${
              selectedServiceType === 'diagnostic' 
                ? 'lg:grid-cols-3 max-w-5xl mx-auto' 
                : 'lg:grid-cols-4'
            } gap-4 mt-8`}>
              {currentPackages.map((pkg) => (
                <div 
                  key={pkg.id} 
                  className="flex flex-col items-center bg-white rounded-lg shadow-md overflow-hidden transform transition-all hover:scale-105 hover:shadow-xl p-6"
                >
                  <div className="bg-gray-200 rounded-full p-8 mb-4">
                    <FontAwesomeIcon 
                      icon={
                        pkg.id === 'pre-1' ? faMagnifyingGlass :
                        pkg.id === 'pre-2' ? faClipboardList :
                        pkg.id === 'pre-3' ? faStar :
                        pkg.id === 'pre-4' ? faCrown :
                        pkg.id === 'seas-1' ? faLeaf :
                        pkg.id === 'seas-2' ? faSun :
                        pkg.id === 'seas-3' ? faTree :
                        pkg.id === 'seas-4' ? faSnowflake :
                        pkg.id === 'diag-1' ? faWrench :
                        pkg.id === 'diag-2' ? faScrewdriverWrench :
                        faGear
                      } 
                      className="text-5xl text-gray-700"
                    />
                  </div>
                  <h3 className="text-lg font-bold text-gray-900 mb-2">{pkg.name}</h3>
                  <div className="text-xl font-bold text-gray-900 mb-4">${pkg.price.toFixed(2)}</div>
                  <button
                    onClick={(e) => {
                      if (trackClick) {
                        trackClick(e);
                      }
                      handlePackageSelect(pkg);
                    }}
                    className="w-full bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-colors"
                  >
                    Select Package
                  </button>
                </div>
              ))}
            </div>
          </div>
        );
      
      case 3:
        // Booking form step
        return (
          <div className="max-w-3xl mx-auto">
            <div className="bg-white rounded-lg shadow-md p-8">
              <h2 className="text-2xl font-semibold mb-6">Booking Details</h2>
              
              {/* Selected Package Summary */}
              {selectedPackage && (
                <div className="mb-8 p-4 bg-gray-50 rounded-lg">
                  <h3 className="font-semibold text-lg mb-2">Selected Package</h3>
                  <p className="text-gray-700">{selectedPackage.name}</p>
                  <p className="text-primary-600 font-semibold">${selectedPackage.price.toFixed(2)}</p>
                </div>
              )}

              {/* Booking Form */}
              <form onSubmit={handleBookingSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Preferred Date
                  </label>
                  <input
                    type="date"
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Vehicle Information
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      placeholder="Make"
                      required
                      className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                    <input
                      type="text"
                      placeholder="Model"
                      required
                      className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                    <input
                      type="text"
                      placeholder="Year"
                      required
                      className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                    <input
                      type="text"
                      placeholder="VIN (Optional)"
                      className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Contact Information
                  </label>
                  <div className="space-y-4">
                    <input
                      type="text"
                      placeholder="Full Name"
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                    <input
                      type="tel"
                      placeholder="Phone"
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Additional Notes
                  </label>
                  <textarea
                    rows={4}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    placeholder="Any special requests or information we should know..."
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-primary-600 text-white py-3 px-4 rounded-lg hover:bg-primary-700 transition-colors"
                >
                  Continue to Payment
                </button>
              </form>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gray-50" aria-label="Service Booking Page">
        <Helmet>
          <title>Book Service | Auto Inspection Pro</title>
        </Helmet>
        <Navbar />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Validation Errors Display */}
          {Object.keys(validationErrors).length > 0 && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
              <ul>
                {Object.entries(validationErrors).map(([key, message]) => (
                  <li key={key}>{message}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="text-center pt-16 pb-8">
            <h1 className="text-3xl font-bold text-gray-900">Schedule Service</h1>
            <p className="mt-2 text-lg text-gray-600">
              Choose a service package
            </p>
          </div>

          {renderStepContent()}
        </div>
      </div>
    </ErrorBoundary>
  );
}