import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Default no-op functions to prevent undefined errors
const noOpFunction = () => {
  console.warn('Analytics method not available');
};

export const useAnalytics = (analytics?: any) => {
  const location = useLocation();

  useEffect(() => {
    // Track page views automatically, with null check
    if (analytics?.trackEvent) {
      analytics.trackEvent('page_view', { page: location.pathname });
    }
  }, [location, analytics]);

  return {
    trackEvent: analytics?.trackEvent?.bind(analytics) || noOpFunction,
    trackButtonClick: analytics?.trackButtonClick?.bind(analytics) || noOpFunction,
    trackFormSubmit: analytics?.trackFormSubmit?.bind(analytics) || noOpFunction,
    trackServiceView: analytics?.trackServiceView?.bind(analytics) || noOpFunction,
  };
};
