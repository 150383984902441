import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider } from './context/CartContext';
import { AuthProvider } from './context/AuthContext';
import { AdminProvider } from './contexts/AdminContext';
import { VehicleProvider } from './context/VehicleContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// Components
import ScrollToTop from './components/ScrollToTop';

// Pages
import Home from './pages/Home';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import WhyChooseUs from './pages/WhyChooseUs';
import BookingPage from './pages/BookingPage';
import BookingCart from './pages/BookingCart';
import PaymentPage from './pages/PaymentPage';
import AddOnsPage from './pages/AddOnsPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import DashboardPage from './pages/DashboardPage';
import GaragePage from './pages/GaragePage';
import RequestAppointment from './pages/RequestAppointment';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import Accessibility from './pages/Accessibility';
import FAQPage from './pages/FAQPage';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import AdminDashboard from './pages/admin/AdminDashboard';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import ConfirmationPage from './pages/ConfirmationPage';

// Service Pages
import PrePurchaseServices from './pages/PrePurchaseServices';
import SeasonalServices from './pages/SeasonalServices';
import DiagnosticServices from './pages/DiagnosticServices';
import FleetServices from './pages/FleetServices';
import ElectricalServices from './pages/ElectricalServices';
import PerformanceServices from './pages/PerformanceServices';
import OilAnalysis from './pages/OilAnalysis';
import TestDrive from './pages/TestDrive';
import MarketValue from './pages/MarketValue';
import PackageDetails from './pages/PackageDetails';

const paypalInitialOptions = {
  "client-id": import.meta.env.VITE_PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
  components: "buttons,funding-eligibility,messages",
  "enable-funding": "card,paypal",
  "disable-funding": "venmo,credit",
  locale: "en_US",
  "buyer-country": "US",
  commit: true,
  vault: false,
  debug: import.meta.env.DEV,
  "data-partner-attribution-id": "YourCompanyName_SP",
  "data-client-token": import.meta.env.VITE_PAYPAL_CLIENT_TOKEN || undefined
};

function App() {
  return (
    <HelmetProvider>
      <PayPalScriptProvider 
        options={paypalInitialOptions}
        deferLoading={false}
      >
        <div className="App">
          <AuthProvider>
            <AdminProvider>
              <VehicleProvider>
                <CartProvider>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/why-choose-us" element={<WhyChooseUs />} />
                    
                    {/* Booking and Service Routes */}
                    <Route path="/booking" element={<BookingPage />} />
                    <Route path="/addons" element={<AddOnsPage />} />
                    <Route path="/cart" element={<BookingCart />} />
                    <Route path="/payment" element={<PaymentPage />} />
                    <Route path="/confirmation" element={<ConfirmationPage />} />
                    
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/garage" element={<GaragePage />} />
                    <Route path="/request-appointment" element={<RequestAppointment />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<BlogPost />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                    <Route path="/accessibility" element={<Accessibility />} />
                    <Route path="/faq" element={<FAQPage />} />
                    
                    {/* Admin Routes */}
                    <Route 
                      path="/admin/dashboard" 
                      element={
                        <ProtectedAdminRoute>
                          <AdminDashboard />
                        </ProtectedAdminRoute>
                      } 
                    />
                    
                    {/* Service Type Routes */}
                    <Route path="/services/pre-purchase" element={<PrePurchaseServices />} />
                    <Route path="/services/seasonal" element={<SeasonalServices />} />
                    <Route path="/services/diagnostic" element={<DiagnosticServices />} />
                    <Route path="/services/fleet" element={<FleetServices />} />
                    <Route path="/services/electrical" element={<ElectricalServices />} />
                    <Route path="/services/performance" element={<PerformanceServices />} />
                    <Route path="/services/oil-analysis" element={<OilAnalysis />} />
                    <Route path="/services/test-drive" element={<TestDrive />} />
                    <Route path="/services/market-value" element={<MarketValue />} />

                    {/* Package Detail Routes */}
                    <Route path="/services/:serviceType/:id" element={<PackageDetails />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  <ToastContainer 
                    position="bottom-right" 
                    autoClose={3000} 
                    hideProgressBar={false} 
                    newestOnTop={false} 
                    closeOnClick 
                    rtl={false} 
                    pauseOnFocusLoss 
                    draggable 
                    pauseOnHover 
                  />
                </CartProvider>
              </VehicleProvider>
            </AdminProvider>
          </AuthProvider>
        </div>
      </PayPalScriptProvider>
    </HelmetProvider>
  );
}

export default App;