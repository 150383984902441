export const servicePricing = {
  prePurchase: {
    essentialCheck: 149.99,
    completeCondition: 249.99,
    signature: 399.99,
    elite: 749.99,
  },
  seasonal: {
    springCheck: 99.99,
    summerReady: 99.99,
    autumnSafety: 99.99,
    winterShield: 99.99,
  },
  diagnostic: {
    basic: 79.99,
    advanced: 149.99,
    expert: 199.99,
  },
} as const;

export type ServicePricing = typeof servicePricing;
