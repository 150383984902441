import { useEffect, useRef } from 'react';
import { trackUserEngagement } from '../services/analytics/enhancedAnalytics';

export const useScrollTracking = (pageId: string) => {
  const scrollThresholds = useRef(new Set([25, 50, 75, 90, 100]));
  const maxScrollDepth = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      
      // Calculate scroll depth as a percentage
      const scrollDepth = Math.round((scrollTop + windowHeight) / documentHeight * 100);
      
      if (scrollDepth > maxScrollDepth.current) {
        maxScrollDepth.current = scrollDepth;
        
        // Check which thresholds have been passed
        scrollThresholds.current.forEach(threshold => {
          if (scrollDepth >= threshold) {
            trackUserEngagement.scroll(threshold, pageId);
            scrollThresholds.current.delete(threshold);
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [pageId]);
};
