import { motion, useScroll, useTransform } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Shield, Home, Wrench } from 'lucide-react';

export default function Hero() {
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);
  const scale = useTransform(scrollY, [0, 300], [1, 0.8]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  const buttonVariants = {
    hover: { 
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    },
    tap: { 
      scale: 0.95 
    }
  };

  return (
    <div className="relative pt-20 bg-white">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-24">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.h1 
              variants={itemVariants}
              className="text-4xl md:text-5xl font-bold text-primary-600 leading-tight"
            >
              Colorado's Premier Auto Inspection Service
            </motion.h1>
            <div className="mt-6 space-y-2">
              <motion.p 
                variants={itemVariants}
                className="text-2xl md:text-3xl text-gray-800 font-semibold"
              >
                Driven by passion, guided by experience:
              </motion.p>
              <motion.p 
                variants={itemVariants}
                className="text-xl text-gray-600"
              >
                Your path to smart car buying
              </motion.p>
            </div>
            <motion.div 
              variants={itemVariants}
              className="mt-8 flex flex-col sm:flex-row gap-4"
            >
              <motion.div
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <Link 
                  to="/booking"
                  className="bg-primary-600 text-white px-8 py-3 rounded-xl hover:bg-primary-700 transition text-center block shadow-2xl hover:shadow-primary-600/50"
                >
                  Book Inspection
                </Link>
              </motion.div>
              <motion.div
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <Link 
                  to="/services" 
                  className="border-2 border-gray-300 text-gray-700 px-8 py-3 rounded-xl hover:border-primary-600 hover:text-primary-600 transition text-center block"
                >
                  View Services
                </Link>
              </motion.div>
            </motion.div>
            <motion.div 
              variants={containerVariants}
              className="mt-12 grid grid-cols-3 gap-6"
            >
              {[
                { icon: Shield, text: 'ASE Certified' },
                { icon: Home, text: 'Locally Owned & Operated' },
                { icon: Wrench, text: '200+ Point Check' }
              ].map((item, index) => (
                <motion.div 
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  className="flex items-center gap-2"
                >
                  <motion.div
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.5 }}
                  >
                    <item.icon className="h-5 w-5 text-primary-600" />
                  </motion.div>
                  <span className="text-sm font-medium text-gray-700">{item.text}</span>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
          <motion.div
            style={{ scale }}
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ 
              duration: 0.6, 
              delay: 0.4,
              type: "spring",
              stiffness: 100
            }}
            className="relative"
          >
            <motion.div 
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 400 }}
              className="relative rounded-xl overflow-hidden shadow-2xl border-2 border-gray-200"
            >
              <img
                src="https://i.ibb.co/sKrPJYd/IMG-0313.jpg"
                alt="Luxury car inspection"
                className="w-full h-[400px] object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white/60 to-transparent"></div>
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
                src="https://i.ibb.co/rfR4yw3/Logo-cleaned.png"
                alt="CARS Logo"
                className="absolute bottom-4 right-4 h-12 w-auto"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}