import analytics from './analyticsService';

export const trackUserEngagement = {
  // Page Views
  pageView: (pageName: string, additionalData = {}) => {
    analytics.trackPageView(pageName, {
      referrer: document.referrer,
      url: window.location.href,
      screen_size: `${window.innerWidth}x${window.innerHeight}`,
      ...additionalData
    });
  },

  // Service Related
  serviceView: (service: any) => {
    analytics.trackServiceView(service.name, {
      service_price: service.price,
      service_category: service.category,
      service_duration: service.duration,
      timestamp: new Date().toISOString()
    });
  },

  serviceInteraction: (service: any, interactionType: string) => {
    analytics.trackEvent('service_interaction', {
      service_name: service.name,
      interaction_type: interactionType,
      timestamp: new Date().toISOString()
    });
  },

  // Booking Flow
  bookingStarted: (service: any, location: string) => {
    analytics.trackEvent('booking_started', {
      service_name: service.name,
      service_price: service.price,
      location,
      timestamp: new Date().toISOString()
    });
  },

  bookingStepCompleted: (step: number, data: any) => {
    analytics.trackEvent('booking_step_completed', {
      step_number: step,
      step_name: getStepName(step),
      ...data,
      timestamp: new Date().toISOString()
    });
  },

  bookingCompleted: (bookingData: any) => {
    analytics.trackEvent('booking_completed', {
      service_name: bookingData.service,
      service_price: bookingData.price,
      location: bookingData.location,
      date: bookingData.date,
      time: bookingData.time,
      ...bookingData,
      timestamp: new Date().toISOString()
    });
  },

  // Blog Engagement
  blogView: (post: any) => {
    analytics.trackEvent('blog_view', {
      post_id: post.id,
      post_title: post.title,
      post_category: post.category,
      author: post.author,
      timestamp: new Date().toISOString()
    });
  },

  blogEngagement: (post: any, action: string) => {
    analytics.trackEvent('blog_engagement', {
      post_id: post.id,
      action,
      timestamp: new Date().toISOString()
    });
  },

  // User Interactions
  buttonClick: (buttonId: string, context: string) => {
    analytics.trackButtonClick(buttonId, {
      context,
      timestamp: new Date().toISOString()
    });
  },

  formInteraction: (formId: string, action: string, data: any = {}) => {
    analytics.trackFormSubmit(formId, {
      action,
      ...data,
      timestamp: new Date().toISOString()
    });
  },

  // Search and Navigation
  search: (query: string, results: number) => {
    analytics.trackEvent('search', {
      query,
      results_count: results,
      timestamp: new Date().toISOString()
    });
  },

  navigation: (from: string, to: string) => {
    analytics.trackEvent('navigation', {
      from_path: from,
      to_path: to,
      timestamp: new Date().toISOString()
    });
  },

  // Error Tracking
  error: (error: Error, context: string) => {
    analytics.trackEvent('error', {
      error_message: error.message,
      error_stack: error.stack,
      context,
      timestamp: new Date().toISOString()
    });
  },

  // Performance Metrics
  performance: () => {
    const perf = window.performance.timing;
    analytics.trackEvent('performance', {
      dns_time: perf.domainLookupEnd - perf.domainLookupStart,
      connection_time: perf.connectEnd - perf.connectStart,
      ttfb: perf.responseStart - perf.navigationStart,
      dom_load: perf.domContentLoadedEventEnd - perf.navigationStart,
      load_time: perf.loadEventEnd - perf.navigationStart,
      timestamp: new Date().toISOString()
    });
  },

  // User Behavior
  scroll: (depth: number, pageId: string) => {
    analytics.trackEvent('scroll_depth', {
      depth,
      page_id: pageId,
      timestamp: new Date().toISOString()
    });
  },

  sessionStart: () => {
    analytics.trackEvent('session_start', {
      referrer: document.referrer,
      user_agent: navigator.userAgent,
      timestamp: new Date().toISOString()
    });
  },

  // Feature Usage
  featureUsage: (featureId: string, action: string, data: any = {}) => {
    analytics.trackEvent('feature_usage', {
      feature_id: featureId,
      action,
      ...data,
      timestamp: new Date().toISOString()
    });
  }
};

function getStepName(step: number): string {
  const steps = {
    1: 'Service Selection',
    2: 'Vehicle Information',
    3: 'Location & Date',
    4: 'Contact Details',
    5: 'Review & Payment'
  };
  return steps[step as keyof typeof steps] || `Step ${step}`;
}
