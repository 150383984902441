import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ServiceCard from './ServiceCard';
import { ArrowRight, Search } from 'lucide-react';
import { servicePricing } from '../config/pricing';

const prePurchasePackages = [
  {
    title: "Essential Check",
    price: servicePricing.prePurchase.essentialCheck.toString(),
    features: [
      "Visual Body Condition Assessment",
      "Basic Mechanical Inspection",
      "Fluid Level Checks",
      "Basic Photo Documentation",
      "Tire Condition Check",
      "50+ Point Checklist"
    ],
    details: {
      visualBody: "The visual body condition assessment is a systematic evaluation of the vehicle's exterior surfaces and structure. This assessment focuses on identifying any signs of damage, corrosion, or misalignment in body panels and paint conditions. By conducting this inspection, you can make informed decisions regarding repairs and maintenance",
      mechanical: "A basic mechanical inspection is the cornerstone of ensuring your vehicle runs smoothly and safely. This assessment covers essential systems such as the engine, transmission, brakes, and exhaust. By identifying and addressing any issues, it can prevent costly repairs and maintain optimal performance. This inspection is not just a routine check; it's a proactive step towards extending the life of your vehicle and enhancing your driving experience. Embrace this opportunity to keep your car in peak condition, giving you the peace of mind and reliability you deserve.",
      fluids: "Fluid level checks are critical to the proper functioning and safety of your vehicle. Assessment of key fluids, including engine oil, coolant, brake fluid, transmission fluid, and windshield washer fluid, ensures that each system operates effectively. Maintaining appropriate fluid levels helps prevent overheating, brake failure, and other mechanical issues, ultimately enhancing vehicle reliability.",
      photos: "Basic photo documentation is an effective method for maintaining a visual record of a vehicle's condition over time. This includes documenting areas of concern, maintenance activities, and any modifications made. Such documentation is valuable for tracking the vehicle's service history and can serve as a reference in the event of disputes regarding condition or repairs. Proper documentation practices significantly contribute to informed decision-making regarding future maintenance and enhance communication with repair professionals.",
      tires: "A tire condition check is an essential part of vehicle maintenance. This assessment involves examining the tread depth, wear patterns, and overall condition of the tires. Proper tire maintenance is crucial for ensuring optimal handling, fuel efficiency, and safety. Regular inspections help identify and address issues such as uneven wear or low tire pressure, promoting a smoother and safer driving experience.",
      checklist: "The 50+ point checklist is your comprehensive roadmap to vehicle care and maintenance. By covering all essential aspects from mechanical systems and safety features and some aesthetic considerations. This checklist offers a Vehicle Snapshot of its current condition."
    }
  },
  {
    title: "Complete Condition Report",
    price: servicePricing.prePurchase.completeCondition.toString(),
    features: [
      "Basic Check Engine OBD2 Scan",
      "Battery Health Test (12V)",
      "Brake Measurement",
      "Suspension Inspection",
      "100+ Point Checklist"
    ],
    details: {
      visualBody: "A Basic Check Engine OBD2 Scan is a diagnostic procedure that reads the data from your vehicle's onboard computer system. This scan retrieves diagnostic trouble codes (DTCs) and other critical information such as engine performance, emissions, and system status. These codes help identify any malfunctions or issues within the vehicle's systems.",
      mechanical: "A Battery Health Test for a 12-volt battery is a comprehensive assessment designed to evaluate the condition and performance of the vehicle's battery. This test typically involves measuring the battery's voltage, cold cranking amps (CCA), and state of health (SOH) to determine its overall health and ability to hold a charge. Additional tests may include load testing to simulate the battery's performance under actual engine start conditions and internal resistance testing to identify any potential internal failures. The results of these tests help technicians determine whether the battery needs maintenance, replacement, or if other electrical system components are affecting its performance.",
      fluids: "A Brake Measurement inspection is a detailed evaluation of the vehicle's braking system to ensure it is functioning correctly and safely. This inspection includes measuring the thickness of brake pads and rotors to determine wear levels, checking for any signs of damage or excessive wear on brake components, and assessing the brake fluid level and condition. Properly functioning brakes are essential for safe driving, and regular measurements help identify wear and tear, allowing for timely replacement and maintenance.",
      tires: "A Suspension Inspection is a thorough visual examination of all components of the vehicle's suspension system, including shock absorbers, struts, springs, control arms, and bushings. This inspection assesses the condition of these parts to ensure they function correctly and provide a smooth, safe, and stable ride. Issues with the suspension can lead to poor handling, increased wear on tires, and reduced vehicle safety, making regular inspections vital for maintaining vehicle performance and driver safety.",
      checklist: "A 100+ Point Checklist is a comprehensive inspection that covers a wide range of vehicle components and systems. This detailed checklist includes checking the engine, transmission, brakes, suspension, steering, electrical systems, exterior and interior lights, tires, belts, hoses, fluids, and more. The purpose of this inspection is to identify any issues or potential problems, ensuring the vehicle is in optimal condition and safe to operate."
    }
  },
  {
    title: "C.A.R.S Signature Package",
    price: servicePricing.prePurchase.signature.toString(),
    features: [
      "Full System Diagnostic Scan",
      "Laser Tire Scanning",
      "Video Documentation",
      "Full Battery Starting and Charging Report",
      "150+ Point Checklist"
    ],
    isSignature: true,
    details: {
      visualBody: "A comprehensive electronic analysis of your vehicle's onboard computer systems using professional-grade scanning equipment. This process reads and interprets fault codes from all vehicle control modules, including engine, transmission, ABS, airbags, and other electronic systems, providing a complete picture of your vehicle's electronic health and identifying any existing or potential issues.",
      mechanical: "We utilize state-of-the-art laser technology to precisely measure your tire tread depth. This provides a comprehensive overview of tire wear patterns and allows us to identify potential alignment problems or other issues that could affect tire performance and longevity. This ensures your safety and maximizes the life of your tires.",
      fluids: "A systematic video recording of your vehicle's condition, including detailed footage of exterior, interior, undercarriage, and operational tests. This visual documentation captures the vehicle's current state and any existing issues, providing a transparent record of the inspection process and findings.",
      checklist: "A methodical inspection process covering more than 150 critical vehicle components and systems, including mechanical, electrical, safety, and cosmetic elements. Each point is individually assessed and documented by certified technicians, creating a detailed report of the vehicle's overall condition.",
      battery: "A comprehensive analysis of your vehicle's battery and charging system. This detailed report includes battery voltage testing under various conditions, starter motor current draw analysis, and alternator output testing. This ensures your vehicle's electrical system is functioning optimally and helps prevent unexpected battery failures."
    }
  },
  {
    title: "Elite Package",
    price: servicePricing.prePurchase.elite.toString(),
    features: [
      "Factory-Level Testing",
      "Fluid Analysis",
      "Interactive Digital Report",
      "Phone Consultation",
      "200+ Point Checklist"
    ],
    details: {
      visualBody: "A comprehensive diagnostic process using manufacturer-specific tools and software to evaluate your vehicle's electronic systems, sensors, and computerized components. This testing mirrors the depth and precision of inspections performed at authorized dealerships, identifying both current issues and potential future concerns.",
      mechanical: "Onsite fluid analysis encompasses three essential diagnostics to ensure vehicle system integrity. Head gasket integrity is assessed by detecting combustion gases in the coolant using a block tester that samples radiator vapor at operating temperature; a color change from blue to yellow indicates a gasket leak. Brake fluid is evaluated for water content with an electronic moisture meter, where levels exceeding 3% require immediate fluid replacement to maintain braking performance. Coolant integrity is determined through pH testing, freeze point measurement using a refractometer, specific gravity assessment, and visual inspections for contamination. Conducted with calibrated equipment, these procedures provide accurate, actionable data to support maintenance decisions in alignment with manufacturer specifications.",
      fluids: "An interactive digital report is a cutting-edge, user-friendly tool designed to present the outcomes of an automotive inspection in a clear and accessible manner. This report can be accessed on a smartphone, tablet, or computer and offers a comprehensive overview of the vehicle's condition. It includes detailed information about key components, such as the engine, transmission, and brakes, and is often enhanced with images, videos, and diagrams to facilitate understanding. The interactive nature of the report enables users to navigate through the findings effortlessly and access additional details with a simple click.",
      tires: "Phone consultation provides vehicle owners with the opportunity to discuss inspection results, maintenance options, and any concerns directly with automotive experts. This service allows for personalized advice and clarification of technical information, ensuring that owners fully understand the necessary steps to maintain their vehicle's performance and safety. Phone consultations offer convenience and expert support without the need for an in-person visit.",
      checklist: "A 200+ point checklist is an exhaustive list of inspection criteria that covers every aspect of a vehicle's condition, from mechanical components to exterior and interior features. This thorough examination ensures that no detail is overlooked, providing a complete assessment of the vehicle's health and identifying any areas that require attention. Utilizing a 200+ point checklist guarantees a meticulous inspection, enhancing the accuracy and reliability of the overall vehicle evaluation."
    }
  }
];

export default function Services() {
  return (
    <section id="services" className="py-24 relative">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-12">
          <div className="flex items-center gap-4">
            <Search className="w-8 h-8 text-red-600" />
            <div>
              <h2 className="text-3xl font-bold text-gray-900">Pre-Purchase Inspections</h2>
              <p className="mt-2 text-lg text-gray-600">
                Make informed decisions with our comprehensive inspection packages
              </p>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {prePurchasePackages.map((pkg, index) => (
            <ServiceCard
              key={pkg.title}
              title={pkg.title}
              price={pkg.price}
              features={pkg.features}
              isSignature={pkg.isSignature}
              delay={index * 0.1}
              details={pkg.details}
              serviceType="pre-purchase"
            />
          ))}
        </div>
        <div className="mt-12 text-center">
          <Link 
            to="/services"
            className="inline-flex items-center gap-2 bg-red-600 text-white px-8 py-3 rounded-lg hover:bg-red-700 transition"
          >
            View All Services
            <ArrowRight className="w-5 h-5" />
          </Link>
        </div>
      </div>
    </section>
  );
}