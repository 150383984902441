import { motion } from 'framer-motion';
import { Shield, Award, Clock, Wrench, Users, Gauge, FileCheck, DollarSign, Car, Battery, Phone, Laptop, MapPin, Star, CheckCircle, Handshake, Settings, Link, Leaf, MessageSquare, BookOpen, Calendar, Image, Bell, History, TrendingUp, AlertCircle, MessageCircle, ClipboardCheck } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useState } from 'react';

export default function WhyChooseUs() {
  const [activeSection, setActiveSection] = useState('mobile');

  const benefitSections = {
    mobile: {
      title: "Mobile Service Benefits",
      icon: <MapPin className="w-16 h-16 text-primary-600" />,
      benefits: [
        {
          icon: <Clock className="w-12 h-12 text-primary-500" />,
          title: "Convenient On-site Inspections",
          description: "Service at home, office, or preferred location. We bring our expertise directly to you."
        },
        {
          icon: <Car className="w-12 h-12 text-primary-500" />,
          title: "Pre-Purchase Mobile Inspections",
          description: "On-location evaluations for used car purchases, making the buying process convenient and secure."
        },
        {
          icon: <Users className="w-12 h-12 text-primary-500" />,
          title: "Fleet Inspection Services",
          description: "Minimal downtime for business vehicle fleets with our efficient on-site inspection process."
        },
        {
          icon: <CheckCircle className="w-12 h-12 text-primary-500" />,
          title: "Flexible Scheduling",
          description: "Appointments that fit your schedule, including evenings and weekends when available."
        },
        {
          icon: <Award className="w-12 h-12 text-primary-500" />,
          title: "Industry Accreditations",
          description: "Professional association memberships and certifications ensuring top-quality service."
        },
        {
          icon: <FileCheck className="w-12 h-12 text-primary-500" />,
          title: "Certified Inspectors",
          description: "Our team undergoes continuous training and updates to stay current with industry standards."
        },
        {
          icon: <Link className="w-12 h-12 text-primary-500" />,
          title: "Local Business Partnerships",
          description: "Extensive network of automotive service connections for comprehensive support."
        },
        {
          icon: <Settings className="w-12 h-12 text-primary-500" />,
          title: "Customizable Inspection Packages",
          description: "Flexible options based on your specific needs and requirements."
        }
      ]
    },
    hybrid: {
      title: "Hybrid/EV Specific Benefits",
      icon: <Battery className="w-16 h-16 text-primary-600" />,
      benefits: [
        {
          icon: <Award className="w-12 h-12 text-primary-500" />,
          title: "Specialized EV/Hybrid Expertise",
          description: "Technicians certified with Ford and GM High Voltage Certifications for advanced vehicle technologies."
        },
        {
          icon: <Battery className="w-12 h-12 text-primary-500" />,
          title: "Energy Storage System Assessment",
          description: "Detailed battery health evaluations and charging efficiency analysis."
        },
        {
          icon: <Shield className="w-12 h-12 text-primary-500" />,
          title: "High-Voltage System Safety Checks",
          description: "Thorough inspection of electric components and safety systems."
        },
        {
          icon: <Leaf className="w-12 h-12 text-primary-500" />,
          title: "Sustainability Focus",
          description: "Supporting eco-friendly transportation choices with specialized green vehicle services."
        },
        {
          icon: <Gauge className="w-12 h-12 text-primary-500" />,
          title: "Performance Optimization",
          description: "Advanced diagnostics to ensure optimal efficiency and performance of hybrid systems."
        },
        {
          icon: <FileCheck className="w-12 h-12 text-primary-500" />,
          title: "Comprehensive Documentation",
          description: "Detailed reports on battery health, system performance, and maintenance recommendations."
        },
        {
          icon: <Clock className="w-12 h-12 text-primary-500" />,
          title: "Preventive Maintenance",
          description: "Early detection of potential issues specific to hybrid and electric vehicles."
        }
      ]
    },
    digital: {
      title: "Digital & Customer Service Features",
      icon: <Laptop className="w-16 h-16 text-primary-600" />,
      benefits: [
        {
          icon: <FileCheck className="w-12 h-12 text-primary-500" />,
          title: "Digital Reporting",
          description: "Easy access to comprehensive inspection reports via email or our online portal."
        },
        {
          icon: <DollarSign className="w-12 h-12 text-primary-500" />,
          title: "Online Payments",
          description: "User-friendly platform for seamless service requests and secure payment processing."
        },
        {
          icon: <Phone className="w-12 h-12 text-primary-500" />,
          title: "Exceptional Customer Support",
          description: "Dedicated support team available before, during, and after your inspection."
        },
        {
          icon: <MessageSquare className="w-12 h-12 text-primary-500" />,
          title: "Customer Survey Feedback Loop",
          description: "Regular collection and implementation of client feedback for continuous service improvement."
        },
        {
          icon: <BookOpen className="w-12 h-12 text-primary-500" />,
          title: "Educational Content",
          description: "Access to informative articles and resources for vehicle maintenance and care."
        },
        {
          icon: <Image className="w-12 h-12 text-primary-500" />,
          title: "Photo Documentation",
          description: "Detailed visual documentation of inspection findings with annotated images."
        },
        {
          icon: <Bell className="w-12 h-12 text-primary-500" />,
          title: "Service Notifications",
          description: "Automated updates about your inspection status and maintenance reminders."
        },
        {
          icon: <History className="w-12 h-12 text-primary-500" />,
          title: "Service History Tracking",
          description: "Digital record-keeping of all your past inspections and maintenance records."
        }
      ]
    },
    general: {
      title: "General Benefits",
      icon: <Star className="w-16 h-16 text-primary-600" />,
      benefits: [
        {
          icon: <Shield className="w-12 h-12 text-primary-500" />,
          title: "Peace of Mind",
          description: "Know the true condition of your vehicle through comprehensive, unbiased inspections."
        },
        {
          icon: <TrendingUp className="w-12 h-12 text-primary-500" />,
          title: "Increased Resale Value",
          description: "Clean inspection reports enhance vehicle value and attract potential buyers."
        },
        {
          icon: <AlertCircle className="w-12 h-12 text-primary-500" />,
          title: "Safety Assurance",
          description: "Thorough identification and documentation of potential safety hazards."
        },
        {
          icon: <MessageCircle className="w-12 h-12 text-primary-500" />,
          title: "Transparent Communication",
          description: "Clear, detailed explanations of all inspection findings and recommendations."
        },
        {
          icon: <Clock className="w-12 h-12 text-primary-500" />,
          title: "Reliable and Timely Service",
          description: "Punctual appointments and efficient completion of all inspections."
        },
        {
          icon: <Car className="w-12 h-12 text-primary-500" />,
          title: "Thorough Test Drives",
          description: "Comprehensive evaluation of vehicle performance and handling characteristics."
        },
        {
          icon: <Wrench className="w-12 h-12 text-primary-500" />,
          title: "Expert Diagnostics",
          description: "Advanced diagnostic equipment and expertise to identify complex issues."
        },
        {
          icon: <ClipboardCheck className="w-12 h-12 text-primary-500" />,
          title: "Detailed Checklists",
          description: "Comprehensive 200+ point inspection process covering all major systems."
        }
      ]
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 20,
      scale: 0.9
    },
    visible: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 100
      }
    },
    hover: {
      scale: 1.05,
      rotate: 2,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <div className="min-h-screen bg-neutral-50">
      <Navbar />
      <div className="container mx-auto px-4 py-16">
        <motion.div 
          initial="hidden"
          animate="visible"
          className="text-center mb-12"
        >
          <motion.h1 
            variants={itemVariants}
            className="text-5xl font-bold text-neutral-900 mb-4"
          >
            Why Choose C.A.R.S
          </motion.h1>
          <motion.p 
            variants={itemVariants}
            className="text-xl text-neutral-600 max-w-3xl mx-auto"
          >
            Discover the comprehensive, customer-focused approach that sets us apart in automotive inspection services.
          </motion.p>
        </motion.div>

        {/* Section Tabs */}
        <motion.div 
          initial="hidden"
          animate="visible"
          className="flex justify-center mb-12 space-x-4"
        >
          {Object.keys(benefitSections).map((section) => (
            <motion.button
              key={section}
              variants={itemVariants}
              whileHover="hover"
              className={`px-6 py-3 rounded-lg transition-all duration-300 ${
                activeSection === section 
                  ? 'bg-primary-600 text-white shadow-3d-medium' 
                  : 'bg-neutral-200 text-neutral-700 hover:bg-neutral-300'
              }`}
              onClick={() => setActiveSection(section)}
            >
              {benefitSections[section].title}
            </motion.button>
          ))}
        </motion.div>

        {/* Benefits Grid */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="grid md:grid-cols-4 gap-6"
        >
          {benefitSections[activeSection].benefits.map((benefit, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              whileHover="hover"
              className="bg-white rounded-xl shadow-3d-light p-6 text-center 
              transform transition-all duration-300 hover:shadow-3d-medium"
            >
              <div className="mb-4 flex justify-center text-primary-500">
                {benefit.icon}
              </div>
              <h3 className="text-lg font-semibold text-neutral-800 mb-2">
                {benefit.title}
              </h3>
              <p className="text-neutral-600">
                {benefit.description}
              </p>
            </motion.div>
          ))}
        </motion.div>

        {/* CTA Section */}
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="text-center mt-16"
        >
          <RouterLink
            to="/booking"
            className="inline-flex items-center gap-2 bg-primary-600 text-white 
            px-10 py-4 rounded-lg text-lg font-semibold 
            hover:bg-primary-700 transition transform hover:scale-105 
            hover:shadow-3d-medium active:scale-95 duration-300"
          >
            Book Your Inspection Now
            <Award className="w-6 h-6 ml-2" />
          </RouterLink>
        </motion.div>
      </div>
    </div>
  );
}