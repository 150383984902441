import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Car } from 'lucide-react';

const fleetServices = [
  {
    title: 'Basic Fleet Package',
    price: '99.99',
    features: [
      'Visual inspection',
      'Basic maintenance check',
      'Fluid level inspection',
      'Tire condition assessment',
      'Basic safety check',
      'Digital report'
    ]
  },
  {
    title: 'Standard Fleet Care',
    price: '199.99',
    features: [
      'Full vehicle inspection',
      'Diagnostic scan',
      'Preventive maintenance',
      'Brake system check',
      'Fleet management report',
      'Maintenance scheduling'
    ]
  },
  {
    title: 'Premium Fleet Management',
    price: '349.99',
    features: [
      'Comprehensive inspection',
      'Advanced diagnostics',
      'Performance analysis',
      'Custom maintenance plans',
      'Priority service',
      'Fleet analytics dashboard'
    ],
    isSignature: true
  }
];

export default function FleetServices() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Fleet Services"
          description="Professional fleet maintenance and management solutions"
          icon={Car}
          services={fleetServices}
          columns={3}
        />
      </div>
    </div>
  );
}