import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Search } from 'lucide-react';
import { prePurchasePackages } from '../data/services';

export default function PrePurchaseServices() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Pre-Purchase Inspections"
          description="Make informed decisions with our comprehensive inspection packages"
          icon={Search}
          services={prePurchasePackages}
        />
      </div>
    </div>
  );
}