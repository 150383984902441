import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';

export default function NotFound() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Page Not Found | C.A.R.S</title>
        <meta name="robots" content="noindex, follow" />
        <meta name="description" content="The page you're looking for cannot be found. Return to C.A.R.S homepage for auto inspection services in Denver." />
      </Helmet>
      <Navbar />
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center justify-center min-h-[70vh] px-4 text-center"
      >
        <h1 className="text-6xl font-bold text-red-600">404</h1>
        <h2 className="mt-4 text-2xl font-semibold text-gray-900">Page Not Found</h2>
        <p className="mt-2 text-gray-600 max-w-md">
          Sorry, we couldn't find the page you're looking for. Please check the URL or return to our homepage.
        </p>
        <div className="mt-8 space-x-4">
          <Link
            to="/"
            className="inline-flex items-center px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200"
          >
            Return Home
          </Link>
          <Link
            to="/services"
            className="inline-flex items-center px-6 py-3 border border-red-600 text-red-600 rounded-lg hover:bg-red-50 transition-colors duration-200"
          >
            View Services
          </Link>
        </div>
      </motion.div>
    </div>
  );
}
