import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Zap } from 'lucide-react';

const electricalServices = [
  {
    title: 'Basic Electrical Check',
    price: '79.99',
    features: [
      'Battery testing',
      'Alternator check',
      'Starter inspection',
      'Basic wiring check',
      'Lighting system test',
      'Written report'
    ]
  },
  {
    title: 'Advanced Electrical',
    price: '149.99',
    features: [
      'Complete system scan',
      'Circuit testing',
      'Parasitic draw test',
      'Computer system check',
      'Sensor diagnostics',
      'Detailed analysis'
    ]
  },
  {
    title: 'Expert Electrical',
    price: '249.99',
    features: [
      'Full diagnostic scan',
      'Module programming',
      'Network testing',
      'Custom solutions',
      'Technical report',
      'Expert consultation'
    ],
    isSignature: true
  }
];

export default function ElectricalServices() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Electrical System Services"
          description="Expert electrical diagnostics and solutions"
          icon={Zap}
          services={electricalServices}
          columns={3}
        />
      </div>
    </div>
  );
}