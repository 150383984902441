import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Accessibility() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Accessibility Statement</h1>
          <p className="text-gray-600 mb-8">Effective Date: November 24, 2024</p>
          
          <div className="prose prose-lg max-w-none">
            <p className="text-gray-600 mb-8">
              At Colorado Automotive Report & Service LLC, we are committed to ensuring that our website, mobile application, and services are accessible to all users, including those with disabilities. We strive to provide a seamless and inclusive experience for everyone, regardless of their abilities or assistive technology.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">1. Accessibility Standards</h2>
            <p className="mb-4">Our goal is to meet or exceed the requirements of the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA. These guidelines are globally recognized standards for making digital content accessible to individuals with a range of disabilities.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">2. Key Features of Accessibility</h2>
            <p className="mb-4">To support accessibility, we have implemented the following features:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Keyboard Navigation: Ensuring all interactive elements can be accessed via keyboard.</li>
              <li>Text Alternatives: Providing alternative text for images and non-text content.</li>
              <li>Readable Content: Ensuring content is clear, well-structured, and easy to understand.</li>
              <li>Resizable Text: Supporting browser zoom and text resizing without loss of functionality.</li>
              <li>Contrast and Visibility: Maintaining high contrast between text and backgrounds for readability.</li>
              <li>Assistive Technology Compatibility: Designing pages to work with screen readers, voice recognition tools, and other assistive technologies.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">3. Ongoing Efforts</h2>
            <p className="mb-4">Accessibility is an ongoing commitment, and we continually strive to improve our website and app. This includes:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Regular accessibility audits and testing.</li>
              <li>Addressing feedback from users with disabilities.</li>
              <li>Updating features to align with the latest accessibility standards and best practices.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">4. Limitations and Areas for Improvement</h2>
            <p className="mb-4">While we are committed to accessibility, there may be some limitations:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Third-Party Content: Some content provided by third parties (e.g., embedded videos or widgets) may not fully meet accessibility standards.</li>
              <li>User-Generated Content: Content added by users may not always be accessible.</li>
            </ul>
            <p className="mb-4">We encourage users to report any accessibility issues so we can address them promptly.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">5. Feedback and Assistance</h2>
            <p className="mb-4">If you experience any accessibility barriers or require assistance, please contact us. Your feedback is invaluable and helps us improve accessibility for all users.</p>
            <p className="mb-4">Contact Information:</p>
            <p className="mb-4">
              Email: AFox@AutoReportServ.CO<br />
              Phone: (303) 908-9815
            </p>
            <p className="mb-4">We aim to respond to accessibility-related inquiries within 2 business days.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">6. Compatibility</h2>
            <p className="mb-4">Our website and mobile application are designed to be compatible with major browsers and assistive technologies, including:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Modern versions of Chrome, Firefox, Safari, and Edge.</li>
              <li>Screen readers such as NVDA, JAWS, and VoiceOver.</li>
            </ul>
            <p className="mb-4">For the best experience, we recommend keeping your browser and assistive technology updated.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">7. Third-Party Platforms</h2>
            <p className="mb-4">Some of our services may be hosted on third-party platforms (e.g., payment processors). While we strive to work with accessible platforms, we cannot guarantee full accessibility of third-party tools and content.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">8. Continuous Improvement</h2>
            <p className="mb-4">We are dedicated to ongoing accessibility improvements and welcome your suggestions on how we can enhance your experience.</p>

            <p className="text-gray-600 mt-8">
              Colorado Automotive Report & Service LLC is proud to prioritize accessibility and inclusion. Thank you for choosing our services, and please don't hesitate to reach out with any accessibility concerns or feedback.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
