import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCart, AddOn } from '../context/CartContext';
import { 
  Search, 
  Camera, 
  FileText, 
  Droplet, 
  Thermometer, 
  BarChart, 
  Zap, 
  CloudDrizzle, 
  PieChart,
  ChevronDown,  
  ChevronUp,
  ArrowRight,
  ArrowLeft,
  Package      
} from 'lucide-react';
import Navbar from '../components/Navbar';

// Categorized Add-ons
const AVAILABLE_ADDONS: Record<string, AddOn[]> = {
  'Inspection Services': [
    {
      id: 'paint-thickness',
      name: 'Paint Thickness Testing Package',
      price: 79.99,
      type: 'addon',
      icon: Search,
      description: 'Comprehensive paint thickness and condition analysis using professional-grade measuring tools.',
      category: 'inspection'
    },
    {
      id: 'compression-test',
      name: 'Compression Testing Service',
      price: 129.99,
      type: 'addon',
      icon: Thermometer,
      description: 'Detailed engine compression test to assess overall engine health and potential internal issues.',
      category: 'testing'
    },
    {
      id: 'advanced-cooling',
      name: 'Advanced Cooling System Check',
      price: 99.99,
      type: 'addon',
      icon: CloudDrizzle,
      description: 'Comprehensive cooling system diagnostic including pressure tests, fluid analysis, and component inspection.',
      category: 'inspection'
    }
  ],
  'Diagnostic Services': [
    {
      id: 'extended-electrical',
      name: 'Extended Electrical System Testing',
      price: 149.99,
      type: 'addon',
      icon: Zap,
      description: 'In-depth electrical system diagnostics including battery, alternator, and complex electrical component testing.',
      category: 'testing'
    },
    {
      id: 'exhaust-smoke',
      name: 'Exhaust System Smoke Test',
      price: 89.99,
      type: 'addon',
      icon: CloudDrizzle,
      description: 'Specialized smoke test to detect leaks and assess the integrity of the exhaust system.',
      category: 'testing'
    },
    {
      id: 'fluid-analysis',
      name: 'Fluid Analysis Package',
      price: 119.99,
      type: 'addon',
      icon: Droplet,
      description: 'Comprehensive analysis of vehicle fluids including oil, transmission, brake, and coolant.',
      category: 'testing'
    }
  ],
  'Documentation & Analysis': [
    {
      id: '4k-photo-doc',
      name: '4K Photo Documentation',
      price: 59.99,
      type: 'addon',
      icon: Camera,
      description: 'High-resolution 4K photo documentation of vehicle condition with detailed close-ups.',
      category: 'documentation'
    },
    {
      id: 'market-analysis',
      name: 'Professional Market Analysis',
      price: 199.99,
      type: 'addon',
      icon: PieChart,
      description: 'Comprehensive market valuation and detailed analysis of vehicle worth based on current market trends.',
      category: 'analysis'
    }
  ]
};

const AddOnsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    addOns, 
    addAddOn, 
    removeAddOn, 
    selectedPackage, 
    selectedVehicle,
    setPackage,
    setSelectedVehicle 
  } = useCart();
  const [openCategory, setOpenCategory] = useState<string | null>(null);

  // Debugging log
  useEffect(() => {
    console.log('AddOnsPage - Selected Package:', selectedPackage);
    console.log('AddOnsPage - Selected Vehicle:', selectedVehicle);
    console.log('AddOnsPage - Location State:', location.state);
  }, [selectedPackage, selectedVehicle, location.state]);

  const handleContinueToBooking = useCallback(() => {
    if (!selectedPackage) {
      toast.error('Please select a package before continuing', {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    // Navigate to Booking Page with form step
    navigate('/booking', { 
      state: { 
        fromAddOns: true,
        showBookingForm: true
      } 
    });
  }, [navigate, selectedPackage]);

  const handleBackToServices = useCallback(() => {
    navigate('/booking');
  }, [navigate]);

  // If no package is selected, redirect to booking page
  useEffect(() => {
    if (!selectedPackage) {
      navigate('/booking');
    }
  }, [selectedPackage, navigate]);

  const toggleAddon = (addon: AddOn) => {
    const isAddonPresent = addOns.some(a => a.id === addon.id);
    if (isAddonPresent) {
      removeAddOn(addon.id);
    } else {
      addAddOn(addon);
    }
  };

  const isAddonSelected = (addonId: string) => {
    return addOns.some(addon => addon.id === addonId);
  };

  const CategoryIcons: Record<string, React.ComponentType<{ className?: string }>> = {
    'Inspection Services': Search,
    'Diagnostic Services': Zap,
    'Documentation & Analysis': Camera
  };

  // If no package, don't render the page
  if (!selectedPackage) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Add-On Services | C.A.R.S Vehicle Inspection</title>
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        {/* Selected Package Display */}
        {selectedPackage && (
          <div className="bg-gray-100 rounded-lg p-6 mb-8 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Package className="w-10 h-10 text-primary-600" />
              <div>
                <h2 className="text-2xl font-bold text-gray-900">{selectedPackage.name}</h2>
                <p className="text-gray-600">{selectedPackage.description}</p>
              </div>
            </div>
            <div className="text-2xl font-bold text-primary-600">
              ${selectedPackage.price.toFixed(2)}
            </div>
          </div>
        )}

        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Additional Services & Add-Ons
        </h1>
        
        <div className="grid md:grid-cols-3 gap-6">
          {Object.entries(AVAILABLE_ADDONS).map(([category, addons]) => {
            const CategoryIcon = CategoryIcons[category] || Search;
            const isCategoryOpen = openCategory === category;

            return (
              <div 
                key={category} 
                className={`
                  bg-white shadow-lg rounded-2xl overflow-hidden 
                  transition-all duration-300 ease-in-out
                  ${isCategoryOpen 
                    ? 'scale-105 ring-4 ring-primary-500/50' 
                    : 'hover:scale-105 hover:shadow-xl'}
                `}
              >
                <button 
                  onClick={() => setOpenCategory(isCategoryOpen ? null : category)}
                  className="w-full flex flex-col items-center justify-center p-6 space-y-4 focus:outline-none group relative"
                >
                  <div className="bg-primary-100 rounded-full p-6 mb-4">
                    <CategoryIcon 
                      className="w-16 h-16 text-primary-600 transition-transform duration-300"
                      strokeWidth={1.5} 
                    />
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 text-center">
                    {category}
                  </h2>
                  
                  {/* Interactive Arrow Indicator */}
                  <div className="absolute bottom-2 right-2 text-gray-500 group-hover:text-primary-600 transition-colors">
                    {isCategoryOpen ? (
                      <ChevronUp className="w-6 h-6 animate-pulse" />
                    ) : (
                      <ChevronDown className="w-6 h-6 group-hover:scale-110 transition-transform" />
                    )}
                  </div>
                </button>
                
                {isCategoryOpen && (
                  <div className="p-6 bg-gray-50 space-y-4">
                    {addons.map((addon) => (
                      <div 
                        key={addon.id} 
                        className={`
                          border rounded-lg p-4 transition-all duration-300 
                          ${isAddonSelected(addon.id) 
                            ? 'border-primary-600 bg-white shadow-md' 
                            : 'border-gray-200 hover:border-primary-300'}
                        `}
                      >
                        <div className="flex items-center mb-4">
                          <addon.icon className="w-8 h-8 mr-4 text-primary-600" />
                          <h3 className="text-lg font-semibold text-gray-900">{addon.name}</h3>
                        </div>
                        <p className="text-gray-600 mb-4 text-sm">{addon.description}</p>
                        <div className="flex justify-between items-center">
                          <span className="text-xl font-bold text-primary-600">
                            ${addon.price.toFixed(2)}
                          </span>
                          <button
                            onClick={() => toggleAddon(addon)}
                            className={`
                              px-4 py-2 rounded-lg transition-colors
                              ${isAddonSelected(addon.id) 
                                ? 'bg-primary-600 text-white hover:bg-primary-700' 
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}
                            `}
                          >
                            {isAddonSelected(addon.id) ? 'Remove' : 'Add'}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-8 space-x-4">
          <button 
            onClick={handleBackToServices}
            className="flex items-center justify-center px-6 py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors group"
          >
            <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform" />
            Back to Services
          </button>
          <button 
            onClick={handleContinueToBooking}
            className={`
              flex items-center justify-center px-6 py-3 rounded-lg transition-colors group
              ${addOns.length > 0 
                ? 'bg-primary-600 text-white hover:bg-primary-700' 
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'}
            `}
            disabled={addOns.length === 0}
          >
            Continue to Booking
            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </div>
    </>
  );
};

export default AddOnsPage;
