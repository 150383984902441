import { useCallback } from 'react';
import { trackUserEngagement } from '../services/analytics/enhancedAnalytics';

export const useClickTracking = () => {
  const trackClick = useCallback((event?: React.MouseEvent<HTMLElement> | { target?: string }) => {
    try {
      // If no event is passed, do nothing
      if (!event) return;

      let buttonId = 'unknown';
      let context = 'unknown';

      // Handle different event types
      if (event instanceof MouseEvent) {
        const element = event.currentTarget as HTMLElement;
        
        // Additional null checks
        if (!element) {
          console.warn('No current target found in click event');
          return;
        }

        // Get button details
        buttonId = element.id || element.textContent?.trim() || 'unknown';
        context = `${element.tagName.toLowerCase()}_${window.location.pathname}`;
      } else if (typeof event === 'object' && 'target' in event) {
        // Handle custom object with target
        buttonId = event.target?.toString() || 'unknown';
        context = `custom_${window.location.pathname}`;
      }

      // Track the click event
      trackUserEngagement.buttonClick(buttonId, context);
    } catch (error) {
      console.error('Error in click tracking:', error);
    }
  }, []);

  return trackClick;
};
