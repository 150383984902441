import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Process from '../components/Process';
import ServiceSlider from '../components/ServiceSlider';
import ScrollingBanner from '../components/ScrollingBanner';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>C.A.R.S - Professional Auto Inspection Services in Denver | Colorado Automotive Report & Service</title>
        <meta name="description" content="Expert auto inspection services in Denver Metro Area. Pre-purchase inspections, diagnostics, and maintenance by certified professionals. Get peace of mind with C.A.R.S." />
        <meta name="keywords" content="car inspection Denver, auto inspection Colorado, pre-purchase car inspection, vehicle diagnostic services, professional auto inspection" />
        <link rel="canonical" href="https://autoreportserv.co" />
      </Helmet>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen bg-white text-gray-900"
      >
        <Navbar />
        <Hero />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <ServiceSlider />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <ScrollingBanner />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <Services />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          <Process />
        </motion.div>
        <Footer />
      </motion.div>
    </>
  );
}