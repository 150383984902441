import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Car } from 'lucide-react';

const testDriveServices = [
  {
    title: 'Basic Test Drive',
    price: '39.99',
    features: [
      '15-minute drive',
      'Basic handling check',
      'Noise evaluation',
      'Brake performance',
      'Basic report',
      'Recommendations'
    ]
  },
  {
    title: 'Extended Test Drive',
    price: '79.99',
    features: [
      '30-minute drive',
      'Highway testing',
      'Performance analysis',
      'Comfort evaluation',
      'Detailed report',
      'Video documentation'
    ]
  },
  {
    title: 'Premium Test Drive',
    price: '129.99',
    features: [
      '1-hour evaluation',
      'All conditions testing',
      'Full performance check',
      'Advanced diagnostics',
      'Expert analysis',
      'Complete documentation'
    ],
    isSignature: true
  }
];

export default function TestDrive() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Test Drive Services"
          description="Comprehensive road testing and evaluation"
          icon={Car}
          services={testDriveServices}
          columns={3}
        />
      </div>
    </div>
  );
}