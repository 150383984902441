import { ServicePackage } from '../types';

// Pre-Purchase Packages
export const PACKAGES: ServicePackage[] = [
  {
    id: 'pre-1',
    name: 'Essential',
    price: 149.99,
    description: 'A thorough initial assessment of the vehicle\'s condition, perfect for first-time buyers or those looking for a basic inspection. Our certified technicians will examine key components and provide a detailed report of findings.',
    features: [
      'Exterior condition',
      'Basic mechanical inspection',
      'Test drive evaluation',
      'Preliminary report with findings',
      'Same-day digital report delivery'
    ],
    type: 'pre-purchase'
  },
  {
    id: 'pre-2',
    name: 'Complete Condition Report',
    price: 249.99,
    description: 'A comprehensive inspection package that provides a detailed analysis of the vehicle\'s condition, ideal for used car purchases.',
    features: [
      'Complete exterior and interior inspection',
      'Detailed mechanical evaluation',
      'Advanced diagnostic scan',
      'Test drive assessment',
      'Digital report with photos',
      'Vehicle history verification'
    ],
    type: 'pre-purchase'
  },
  {
    id: 'pre-3',
    name: 'C.A.R.S Signature',
    price: 399.99,
    description: 'Our signature inspection service includes a comprehensive evaluation of all vehicle systems, perfect for luxury vehicles or when you need high confidence in your purchase.',
    features: [
      'Comprehensive exterior and interior inspection',
      'Detailed mechanical system check',
      'Advanced diagnostic scan',
      'Comprehensive vehicle history report',
      'Expert consultation',
      'Paint meter readings',
      'Detailed photo documentation',
      'Future maintenance forecast'
    ],
    type: 'pre-purchase'
  },
  {
    id: 'pre-4',
    name: 'Elite Package',
    price: 749.99,
    description: 'Our most comprehensive inspection package, providing an exhaustive analysis of the vehicle\'s condition with premium services and expert consultation.',
    features: [
      'Complete bumper-to-bumper inspection',
      'Full diagnostic analysis',
      'Compression testing',
      'Leak-down testing',
      'Paint thickness measurements',
      'Detailed photo and video documentation',
      'Expert consultation session',
      'Market value assessment',
      'Negotiation support'
    ],
    type: 'pre-purchase'
  }
];

// Seasonal Packages
export const SEASONAL_PACKAGES: ServicePackage[] = [
  {
    id: 'seas-1',
    name: 'Spring-Forward',
    price: 139.99,
    description: 'Get your vehicle ready for spring with our comprehensive inspection and maintenance service.',
    features: [
      'Post-winter system analysis',
      'Brake & suspension test',
      'Fluid systems evaluation',
      'Spring-Forward report'
    ],
    type: 'seasonal'
  },
  {
    id: 'seas-2',
    name: 'Summer Ready',
    price: 164.99,
    description: 'Ensure your vehicle is prepared for hot weather conditions with our comprehensive summer preparation service.',
    features: [
      'Climate control analysis',
      'Cooling system test',
      'Heat stress evaluation',
      'Summer Ready report'
    ],
    type: 'seasonal'
  },
  {
    id: 'seas-3',
    name: 'Autumn Safety',
    price: 159.99,
    description: 'Prepare your vehicle for the changing weather with our comprehensive autumn service package.',
    features: [
      'Cold weather preparation',
      'Battery performance test',
      'Visibility systems check',
      'Autumn Safety report'
    ],
    type: 'seasonal'
  },
  {
    id: 'seas-4',
    name: 'Winter Shield',
    price: 169.99,
    description: 'Get your vehicle winter-ready with our comprehensive cold weather preparation service.',
    features: [
      'Cold start analysis',
      'Heating system test',
      'Traction assessment',
      'Winter Shield report'
    ],
    type: 'seasonal'
  }
];

// Diagnostic Packages
export const DIAGNOSTIC_PACKAGES: ServicePackage[] = [
  {
    id: 'diag-1',
    name: 'Professional Diagnostic Service',
    price: 199.99,
    description: 'Comprehensive diagnostic service to accurately identify and assess vehicle issues. Priced hourly for precise and thorough problem resolution.',
    features: [
      'Detailed computerized diagnostic scan',
      'Comprehensive system analysis',
      'Precise problem identification',
      'Expert technician consultation',
      'Detailed diagnostic report',
      'Recommended repair strategies'
    ],
    type: 'diagnostic'
  }
];

// Mock Inspections Data
export const inspections = [
  {
    id: 'insp-1',
    vehicleName: '2019 Toyota Camry',
    status: 'Completed',
    date: '2024-02-15',
    inspector: 'John Smith',
    location: 'San Francisco Bay Area',
    findings: [
      { issue: 'Brake pads worn', severity: 'Medium' },
      { issue: 'Oil change needed', severity: 'Low' }
    ]
  },
  {
    id: 'insp-2',
    vehicleName: '2020 Honda Civic',
    status: 'In Progress',
    date: '2024-02-20',
    inspector: 'Sarah Johnson',
    location: 'San Jose',
    findings: [
      { issue: 'Tire pressure low', severity: 'Low' }
    ]
  },
  {
    id: 'insp-3',
    vehicleName: '2018 Tesla Model 3',
    status: 'Scheduled',
    date: '2024-02-25',
    inspector: 'Mike Wilson',
    location: 'Mountain View',
    findings: []
  }
];

// Mock Appointments
export const appointments = [
  {
    id: 'apt-1',
    vehicleId: 'veh-1',
    type: 'Inspection',
    date: '2024-03-15',
    time: '10:00 AM',
    location: 'C.A.R.S Main Center',
    status: 'Confirmed',
    notes: 'Pre-purchase inspection for potential buyer'
  },
  {
    id: 'apt-2',
    vehicleId: 'veh-2',
    type: 'Inspection',
    date: '2024-03-20',
    time: '2:30 PM',
    location: 'C.A.R.S Downtown',
    status: 'Pending',
    notes: 'Annual vehicle inspection'
  },
  {
    id: 'apt-3',
    vehicleId: 'veh-3',
    type: 'Inspection',
    date: '2024-04-05',
    time: '11:15 AM',
    location: 'C.A.R.S Main Center',
    status: 'Confirmed',
    notes: 'Complete condition report requested'
  }
];

// Mock Notifications
export const notifications = [
  {
    id: 'notif-1',
    title: 'Inspection Complete',
    message: 'Your vehicle inspection for 2019 Toyota Camry has been completed',
    date: '2024-02-15',
    read: false,
    type: 'inspection'
  },
  {
    id: 'notif-2',
    title: 'Appointment Reminder',
    message: 'You have an upcoming appointment tomorrow at 2:00 PM',
    date: '2024-02-19',
    read: true,
    type: 'appointment'
  },
  {
    id: 'notif-3',
    title: 'Service Recommendation',
    message: 'Based on your last inspection, we recommend scheduling an oil change',
    date: '2024-02-14',
    read: false,
    type: 'service'
  }
];

// Mock Vehicles
export const vehicles = [
  {
    id: 'veh-1',
    make: 'Toyota',
    model: 'Camry',
    year: 2019,
    vin: '1HGCM82633A123456',
    lastInspection: '2024-02-15',
    status: 'Good',
    mileage: 45000,
    nextService: '2024-05-15',
    licensePlate: 'ABC123',
    color: 'Silver'
  },
  {
    id: 'veh-2',
    make: 'Honda',
    model: 'Civic',
    year: 2020,
    vin: '2HGES16575H123456',
    lastInspection: '2024-02-20',
    status: 'Needs Attention',
    mileage: 32000,
    nextService: '2024-04-20',
    licensePlate: 'XYZ789',
    color: 'Blue'
  },
  {
    id: 'veh-3',
    make: 'Tesla',
    model: 'Model 3',
    year: 2018,
    vin: 'WVWAA71F48V123456',
    lastInspection: '2024-01-15',
    status: 'Good',
    mileage: 28000,
    nextService: '2024-06-15',
    licensePlate: 'EV1234',
    color: 'Red'
  }
];

// Mock Reports
export const reports = [
  {
    id: 'rep-1',
    title: 'Pre-Purchase Inspection Report',
    vehicleName: '2019 Toyota Camry',
    date: '2024-02-15',
    type: 'Pre-Purchase',
    status: 'Complete',
    downloadUrl: '#'
  },
  {
    id: 'rep-2',
    title: 'Maintenance Inspection Report',
    vehicleName: '2020 Honda Civic',
    date: '2024-02-20',
    type: 'Maintenance',
    status: 'Pending',
    downloadUrl: '#'
  },
  {
    id: 'rep-3',
    title: 'Annual Inspection Report',
    vehicleName: '2018 Tesla Model 3',
    date: '2024-01-15',
    type: 'Annual',
    status: 'Complete',
    downloadUrl: '#'
  }
];

// Mock History
export const serviceHistory = [
  {
    id: 'serv-1',
    vehicleName: '2019 Toyota Camry',
    vehicleId: 'veh-1',
    serviceType: 'Oil Change',
    serviceDate: '2024-01-15',
    mileage: 45000,
    cost: 89.99,
    status: 'Completed',
    technician: 'John Smith',
    description: 'Regular oil change service with synthetic oil',
    provider: 'C.A.R.S Service Center',
    parts: [
      { name: 'Oil Filter', cost: 15.99 },
      { name: 'Synthetic Oil', cost: 45.99 }
    ]
  },
  {
    id: 'serv-2',
    vehicleName: '2019 Toyota Camry',
    vehicleId: 'veh-1',
    serviceType: 'Brake Pad Replacement',
    serviceDate: '2023-12-10',
    mileage: 43500,
    cost: 299.99,
    status: 'Completed',
    technician: 'Mike Johnson',
    description: 'Front and rear brake pad replacement with rotor inspection',
    provider: 'C.A.R.S Service Center',
    parts: [
      { name: 'Front Brake Pads', cost: 89.99 },
      { name: 'Rear Brake Pads', cost: 89.99 }
    ]
  },
  {
    id: 'serv-3',
    vehicleName: '2020 Honda Civic',
    vehicleId: 'veh-2',
    serviceType: 'Tire Rotation',
    serviceDate: '2024-02-01',
    mileage: 32000,
    cost: 49.99,
    status: 'Completed',
    technician: 'Sarah Wilson',
    description: 'Standard tire rotation service with balance check',
    provider: 'C.A.R.S Service Center',
    parts: []
  }
];
