import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { LineChart } from 'lucide-react';

const marketValueServices = [
  {
    title: 'Basic Value Report',
    price: '29.99',
    features: [
      'Market comparison',
      'Basic condition impact',
      'Local market analysis',
      'Price range estimate',
      'Basic report',
      'Digital delivery'
    ]
  },
  {
    title: 'Detailed Value Report',
    price: '59.99',
    features: [
      'Comprehensive analysis',
      'Historical data',
      'Regional comparisons',
      'Condition assessment',
      'Detailed report',
      'Market trends'
    ]
  },
  {
    title: 'Expert Value Report',
    price: '99.99',
    features: [
      'Professional appraisal',
      'Future value projection',
      'Complete market analysis',
      'Investment guidance',
      'Expert consultation',
      'Premium documentation'
    ],
    isSignature: true
  }
];

export default function MarketValue() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Market Value Analysis"
          description="Professional vehicle valuation services"
          icon={LineChart}
          services={marketValueServices}
          columns={3}
        />
      </div>
    </div>
  );
}