import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function TermsConditions() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Terms and Conditions</h1>
          <p className="text-gray-600 mb-8">Effective Date: November 24, 2024</p>
          
          <div className="prose prose-lg max-w-none">
            <p className="text-gray-600 mb-8">
              Welcome to Colorado Automotive Report & Service LLC. By accessing or using our website, mobile application, or services, you agree to be bound by these Terms and Conditions. Please read them carefully before proceeding. If you do not agree, please discontinue using our services.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">1. Definitions</h2>
            <ul className="list-disc pl-6 mb-4">
              <li>"Company" refers to Colorado Automotive Report & Service LLC.</li>
              <li>"User" refers to any individual or entity accessing or using our website, app, or services.</li>
              <li>"Services" refers to all offerings provided by Colorado Automotive Report & Service LLC, including vehicle inspections and related functionalities.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">2. Eligibility</h2>
            <p className="mb-4">By using our services, you confirm that you:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Are at least 18 years old.</li>
              <li>Have the legal capacity to enter into binding agreements.</li>
              <li>Will use our services solely for lawful purposes and in compliance with these Terms.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">3. Services Offered</h2>
            <p className="mb-4">We provide vehicle inspection services, including but not limited to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Pre-purchase inspections for cars, motorcycles, and commercial vehicles.</li>
              <li>Detailed reports, including photographs and condition assessments.</li>
            </ul>
            <p className="mb-4">While we strive for accuracy, our inspection reports are based on the condition of the vehicle at the time of inspection and do not guarantee future performance or reliability.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">4. Pricing and Payment</h2>
            <ul className="list-disc pl-6 mb-4">
              <li>Service fees are displayed on our website or app and are subject to change without notice.</li>
              <li>Full payment is required at the time of booking.</li>
              <li>Payments are processed securely through our approved payment gateway.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">5. Cancellation and Refund Policy</h2>
            <p className="mb-4">Please review our Cancellation Policy for details on cancellations and refunds.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">6. User Responsibilities</h2>
            <p className="mb-4">By using our services, you agree to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Provide accurate and complete information during the booking process.</li>
              <li>Ensure access to the vehicle for the scheduled inspection.</li>
              <li>Refrain from interfering with or attempting to circumvent our service processes.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">7. Limitations of Liability</h2>
            <ul className="list-disc pl-6 mb-4">
              <li>Colorado Automotive Report & Service LLC is not liable for any losses arising from reliance on our inspection reports, including future repairs or performance of inspected vehicles.</li>
              <li>We do not inspect vehicles for compliance with legal or regulatory requirements unless explicitly stated.</li>
              <li>Our total liability is limited to the amount paid for the service in question.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">8. Intellectual Property</h2>
            <p className="mb-4">All content on our website and app, including text, graphics, logos, and images, is the property of Colorado Automotive Report & Service LLC and protected by copyright laws. Unauthorized use or reproduction is strictly prohibited.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">9. Prohibited Activities</h2>
            <p className="mb-4">You agree not to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Use our services for fraudulent or unlawful purposes.</li>
              <li>Disrupt or interfere with our website, app, or associated systems.</li>
              <li>Reverse engineer or attempt to extract source code from our services.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">10. Privacy</h2>
            <p className="mb-4">Your use of our services is also governed by our Privacy Policy, which explains how we collect, use, and protect your data.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">11. Modifications</h2>
            <p className="mb-4">We reserve the right to modify these Terms and Conditions at any time. Changes will be posted on this page with the updated "Effective Date." Continued use of our services constitutes your acceptance of the revised terms.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">12. Governing Law</h2>
            <p className="mb-4">These Terms and Conditions are governed by the laws of Colorado, without regard to its conflict of law principles.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">13. Contact Information</h2>
            <p className="mb-4">For questions or concerns regarding these Terms and Conditions, please contact us:</p>
            <p className="mb-4">
              Colorado Automotive Report & Service LLC<br />
              Email: afox@autoreportserv.com<br />
              Phone: 303-908-9815
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
