import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Gauge } from 'lucide-react';

const performanceServices = [
  {
    title: 'Basic Performance',
    price: '129.99',
    features: [
      'Engine performance scan',
      'Basic tuning check',
      'Fuel system analysis',
      'Performance baseline',
      'Visual inspection',
      'Basic report'
    ]
  },
  {
    title: 'Advanced Performance',
    price: '249.99',
    features: [
      'Dyno testing',
      'Fuel system diagnosis',
      'Emissions testing',
      'Turbo/supercharger check',
      'Performance tuning',
      'Detailed analysis'
    ]
  },
  {
    title: 'Expert Performance',
    price: '399.99',
    features: [
      'Custom tuning',
      'Race preparation',
      'Advanced diagnostics',
      'Component testing',
      'Track consultation',
      'Complete documentation'
    ],
    isSignature: true
  }
];

export default function PerformanceServices() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Performance Services"
          description="Maximize your vehicle's performance potential"
          icon={Gauge}
          services={performanceServices}
          columns={3}
        />
      </div>
    </div>
  );
}