import { springCheckDetails } from './serviceDescriptions/springCheck';
import { summerReadyDetails } from './serviceDescriptions/summerReady';
import { autumnSafetyDetails } from './serviceDescriptions/autumnSafety';
import { winterShieldDetails } from './serviceDescriptions/winterShield';

export const prePurchasePackages = [
  {
    title: 'Essential',
    price: '149.99',
    features: [
      'Visual body condition assessment',
      'Basic mechanical inspection',
      'Fluid level checks',
      'Tire condition check',
      '50+ point checklist'
    ],
    details: {
      visualBody: "A thorough visual inspection of the vehicle's exterior and interior condition, including paint, body panels, glass, and trim.",
      mechanical: "Basic mechanical inspection covering major components and systems to identify obvious issues.",
      fluids: "Visual inspection of all major fluid levels including oil, coolant, brake fluid, and power steering fluid.",
      tires: "Visual inspection of tire condition, tread depth measurement, and pressure check.",
      checklist: "A comprehensive 50+ point inspection checklist covering essential vehicle systems and components."
    }
  },
  {
    title: 'Complete Condition Report',
    price: '249.99',
    features: [
      'Basic Check Engine OBD2 Scan',
      'Battery Health Test (12V)',
      'Brake Measurement',
      'Suspension Inspection',
      '100+ Point Checklist'
    ],
    details: {
      visualBody: "Basic OBD2 diagnostic scan to check for stored trouble codes and monitor basic engine parameters.",
      mechanical: "Battery health assessment using electronic testing equipment to measure voltage, cold cranking amps, and overall condition.",
      fluids: "Detailed brake system inspection including rotor and pad measurements using precision tools.",
      tires: "Comprehensive suspension inspection checking for wear, damage, and potential safety issues.",
      checklist: "Thorough 100+ point inspection covering all major vehicle systems and components."
    }
  },
  {
    title: 'C.A.R.S Signature Package',
    price: '399.99',
    features: [
      'Full System Diagnostic Scan',
      'Laser Tire Scanning',
      'Video Documentation',
      'Full Battery Starting and Charging Report',
      '150+ Point Checklist'
    ],
    details: {
      visualBody: "Advanced diagnostic scan using professional-grade equipment to evaluate all vehicle systems and modules.",
      mechanical: "Laser-based tire analysis providing detailed measurements of tread depth, wear patterns, and alignment indicators.",
      fluids: "Comprehensive video documentation of the inspection process and findings with expert commentary.",
      tires: "Complete battery and charging system analysis including alternator output and starter performance.",
      checklist: "Extensive 150+ point inspection with detailed documentation and professional recommendations."
    },
    isSignature: true
  },
  {
    title: 'Elite Package',
    price: '749.99',
    features: [
      'Factory-Level Testing',
      'Fluid Analysis',
      'Interactive Digital Report',
      'Phone Consultation',
      '200+ Point Checklist'
    ],
    details: {
      visualBody: "A comprehensive diagnostic process using manufacturer-specific tools and software to evaluate your vehicle's electronic systems, sensors, and computerized components. This testing mirrors the depth and precision of inspections performed at authorized dealerships, identifying both current issues and potential future concerns.",
      mechanical: "Onsite fluid analysis encompasses three essential diagnostics to ensure vehicle system integrity. Head gasket integrity is assessed by detecting combustion gases in the coolant using a block tester that samples radiator vapor at operating temperature; a color change from blue to yellow indicates a gasket leak. Brake fluid is evaluated for water content with an electronic moisture meter, where levels exceeding 3% require immediate fluid replacement to maintain braking performance. Coolant integrity is determined through pH testing, freeze point measurement using a refractometer, specific gravity assessment, and visual inspections for contamination. Conducted with calibrated equipment, these procedures provide accurate, actionable data to support maintenance decisions in alignment with manufacturer specifications.",
      fluids: "An interactive digital report is a cutting-edge, user-friendly tool designed to present the outcomes of an automotive inspection in a clear and accessible manner. This report can be accessed on a smartphone, tablet, or computer and offers a comprehensive overview of the vehicle's condition. It includes detailed information about key components, such as the engine, transmission, and brakes, and is often enhanced with images, videos, and diagrams to facilitate understanding. The interactive nature of the report enables users to navigate through the findings effortlessly and access additional details with a simple click.",
      tires: "Phone consultation provides vehicle owners with the opportunity to discuss inspection results, maintenance options, and any concerns directly with automotive experts. This service allows for personalized advice and clarification of technical information, ensuring that owners fully understand the necessary steps to maintain their vehicle's performance and safety. Phone consultations offer convenience and expert support without the need for an in-person visit.",
      checklist: "A 200+ point checklist is an exhaustive list of inspection criteria that covers every aspect of a vehicle's condition, from mechanical components to exterior and interior features. This thorough examination ensures that no detail is overlooked, providing a complete assessment of the vehicle's health and identifying any areas that require attention. Utilizing a 200+ point checklist guarantees a meticulous inspection, enhancing the accuracy and reliability of the overall vehicle evaluation."
    }
  }
];

export const seasonalServices = [
  {
    id: 'spring-forward',
    title: 'Spring-Forward',
    price: '139.99',
    features: [
      'Post-winter system analysis',
      'Brake & suspension test',
      'Fluid systems evaluation',
      'Spring-Forward report'
    ],
    details: springCheckDetails,
    category: 'seasonal'
  },
  {
    title: 'Summer Ready',
    price: '169.99',
    features: [
      'Climate control analysis',
      'Cooling system test',
      'Heat stress evaluation',
      'Summer Ready report'
    ],
    details: summerReadyDetails
  },
  {
    title: 'Autumn Safety',
    price: '159.99',
    features: [
      'Cold weather preparation',
      'Battery performance test',
      'Visibility systems check',
      'Autumn Safety report'
    ],
    details: autumnSafetyDetails
  },
  {
    title: 'Winter Shield',
    price: '169.99',
    features: [
      'Cold start analysis',
      'Heating system test',
      'Traction assessment',
      'Winter Shield report'
    ],
    details: winterShieldDetails
  }
];

export const diagnosticServices = [
  {
    title: 'Professional Diagnostic Service',
    price: '199.99/hr',
    features: [
      'Full system diagnostic scan',
      'Live data analysis & testing',
      'Circuit & component testing',
      'Comprehensive diagnostic report'
    ],
    details: {
      description: "Our Professional Diagnostic Service provides a thorough analysis of your vehicle's electronic and mechanical systems using state-of-the-art diagnostic equipment. Our certified technicians will perform a comprehensive scan of all vehicle systems, conduct live data analysis to identify any current or potential issues, and perform detailed circuit and component testing where necessary. You'll receive a detailed diagnostic report that includes trouble codes, system performance data, and expert recommendations for addressing any identified issues. This service combines manufacturer-specific testing capabilities with advanced diagnostic techniques to deliver accurate, actionable insights about your vehicle's health. Service is billed at an hourly rate, with most diagnostic sessions completed within 1-2 hours depending on the complexity of the issue."
    }
  }
];