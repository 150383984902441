import { Menu, X, Home, ChevronDown, User, Settings } from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../context/AuthContext';
import { useAdmin } from '../contexts/AdminContext';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { user, logout } = useAuth();
  const { isAdmin } = useAdmin();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const menuVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3
      }
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 }
    }
  };

  return (
    <nav className="fixed w-full bg-neutral-900/95 backdrop-blur-sm z-50 shadow-2xl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link 
              to="/" 
              className="flex items-center transform transition hover:scale-105"
            >
              <img 
                src="https://i.ibb.co/rfR4yw3/Logo-cleaned.png" 
                alt="C.A.R.S Logo" 
                className="h-12 w-auto"
              />
              <div className="ml-2">
                <span className="text-xl font-bold text-primary-500">C.A.R.S</span>
                <span className="hidden md:inline text-sm text-neutral-300 ml-1">
                  Colorado Automotive Report & Service
                </span>
              </div>
            </Link>
          </motion.div>
          
          <div className="hidden md:flex items-center space-x-8">
            <motion.div 
              initial="hidden" 
              animate="visible" 
              variants={menuVariants}
              className="flex items-center space-x-8"
            >
              {[
                { to: '/', label: 'Home', icon: Home },
                { to: '/services', label: 'All Services' },
                { to: '/why-choose-us', label: 'Why Choose Us' },
                { to: '/faq', label: 'FAQ' }
              ].map((item) => (
                <motion.div 
                  key={item.to}
                  variants={itemVariants}
                  whileHover="hover"
                >
                  <Link 
                    to={item.to}
                    className={`flex items-center gap-2 
                      ${location.pathname === item.to 
                        ? 'text-primary-500' 
                        : 'text-neutral-300 hover:text-primary-500'} 
                      transition duration-300`}
                  >
                    {item.icon && <item.icon className="h-5 w-5" />}
                    {item.label}
                  </Link>
                </motion.div>
              ))}
            </motion.div>

            <motion.div 
              initial="hidden" 
              animate="visible" 
              variants={menuVariants}
              className="flex items-center space-x-3"
            >
              <motion.div variants={itemVariants} whileHover="hover">
                <Link
                  to="/contact"
                  className="bg-primary-600 text-white px-4 py-2 rounded-xl 
                  hover:bg-primary-700 transition transform hover:scale-105 
                  hover:shadow-2xl active:scale-95 duration-300"
                >
                  Contact Us
                </Link>
              </motion.div>

              {user ? (
                <div className="relative">
                  <motion.button
                    variants={itemVariants}
                    whileHover="hover"
                    type="button"
                    className="p-2 text-white bg-primary-600 rounded-full 
                    hover:bg-primary-700 transition focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <User className="h-5 w-5" />
                  </motion.button>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div 
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute right-0 mt-2 w-48 rounded-xl shadow-2xl py-1 
                        bg-neutral-800 ring-1 ring-neutral-700 z-50"
                      >
                        {[
                          { to: '/dashboard', label: 'Dashboard' },
                          { to: '/garage', label: 'My Garage' },
                          isAdmin && { to: '/admin/dashboard', label: 'Admin Dashboard', icon: Settings }
                        ].filter(Boolean).map((item) => (
                          <Link
                            key={item.to}
                            to={item.to}
                            className="block px-4 py-2 text-sm text-neutral-300 hover:bg-neutral-700"
                          >
                            {item.label}
                          </Link>
                        ))}
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-neutral-300 hover:bg-neutral-700"
                        >
                          Logout
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <Link
                  to="/login"
                  className="bg-primary-600 text-white px-4 py-2 rounded-xl 
                  hover:bg-primary-700 transition transform hover:scale-105 
                  hover:shadow-2xl active:scale-95 duration-300"
                >
                  Sign In
                </Link>
              )}
            </motion.div>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-neutral-400 
              hover:text-white hover:bg-neutral-800 focus:outline-none focus:ring-2 focus:ring-offset-2 
              focus:ring-offset-neutral-800 focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? <X className="block h-6 w-6" aria-hidden="true" /> : <Menu className="block h-6 w-6" aria-hidden="true" />}
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {[
                { to: '/', label: 'Home' },
                { to: '/services', label: 'All Services' },
                { to: '/why-choose-us', label: 'Why Choose Us' },
                { to: '/faq', label: 'FAQ' }
              ].map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  className={`block px-3 py-2 rounded-md text-base font-medium 
                    ${location.pathname === item.to 
                      ? 'text-primary-500 bg-neutral-900' 
                      : 'text-neutral-300 hover:text-primary-500 hover:bg-neutral-800'} 
                    transition duration-300`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
            <div className="border-t border-neutral-700 pt-4 pb-3">
              <div className="flex items-center px-5">
                {user ? (
                  <>
                    <div className="flex-shrink-0">
                      <User className="h-10 w-10 text-neutral-400" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-neutral-300">{user.name}</div>
                      <div className="text-sm font-medium text-neutral-400">{user.email}</div>
                    </div>
                  </>
                ) : (
                  <Link
                    to="/login"
                    className="bg-primary-600 text-white px-4 py-2 rounded-xl 
                    hover:bg-primary-700 transition transform hover:scale-105 
                    hover:shadow-2xl active:scale-95 duration-300"
                  >
                    Sign In
                  </Link>
                )}
              </div>
              {user && (
                <div className="mt-3 px-2 space-y-1">
                  {[
                    { to: '/dashboard', label: 'Dashboard' },
                    { to: '/garage', label: 'My Garage' }
                  ].map((item) => (
                    <Link
                      key={item.to}
                      to={item.to}
                      className="block px-3 py-2 rounded-md text-base font-medium text-neutral-300 hover:text-primary-500 hover:bg-neutral-800"
                    >
                      {item.label}
                    </Link>
                  ))}
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-neutral-300 hover:text-primary-500 hover:bg-neutral-800"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}