import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import { FileText, History, Settings, User, CalendarDays } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import Reports from '../components/dashboard/Reports';
import Calendar from '../components/calendar/Calendar';
import { useNavigate } from 'react-router-dom';

// Import mock data
import { 
  inspections, 
  reports, 
  notifications,
  serviceHistory,
  appointments 
} from '../data/mockData';

interface Finding {
  issue: string;
  severity: 'high' | 'medium' | 'low';
}

interface Inspection {
  id: string;
  vehicleName: string;
  status: 'Completed' | 'In Progress' | 'Scheduled';
  date: string;
  inspector: string;
  location: string;
  findings: Finding[];
}

interface DashboardTab {
  id: string;
  name: string;
  icon: React.ElementType;
}

const DASHBOARD_TABS: DashboardTab[] = [
  { id: 'overview', name: 'Overview', icon: User },
  { id: 'scheduled', name: 'Scheduled', icon: CalendarDays },
  { id: 'reports', name: 'Reports', icon: FileText },
  { id: 'service-history', name: 'History', icon: History },
  { id: 'settings', name: 'Settings', icon: Settings },
];

export default function DashboardPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const { user } = useAuth();
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [vehicleFilter, setVehicleFilter] = useState('all');
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [smsNotifications, setSmsNotifications] = useState(false);
  const [marketingNotifications, setMarketingNotifications] = useState(false);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone || '');
  const [location, setLocation] = useState(user.location || '');

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Please Sign In</h2>
          <p className="text-gray-600 mb-6">You need to be signed in to access the dashboard.</p>
          <a
            href="/login"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Sign In
          </a>
        </div>
      </div>
    );
  }

  const filteredInspections = inspections.filter(inspection => {
    if (!inspection) return false;
    if (statusFilter === 'all') return true;
    return inspection.status?.toLowerCase() === statusFilter;
  }).filter(inspection => {
    if (!inspection || !inspection.vehicleName) return false;
    return inspection.vehicleName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const renderEmptyState = () => (
    <div className="text-center py-12">
      <div className="mx-auto h-12 w-12 text-gray-400">
        <FileText className="h-12 w-12" />
      </div>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Reports</h3>
      <p className="mt-1 text-sm text-gray-500">
        You haven't had any inspections yet. Schedule your first inspection to get started.
      </p>
      <div className="mt-6">
        <button
          type="button"
          onClick={() => navigate('/booking')}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Schedule Inspection
        </button>
      </div>
    </div>
  );

  const renderEmptyOverview = () => (
    <div className="space-y-8">
      {/* User Profile Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1">
          <div className="bg-gray-50 p-6 rounded-lg">
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                <User className="w-8 h-8 text-blue-600" />
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-medium text-gray-900">{user?.name}</h3>
                <p className="text-sm text-gray-500">Member since {new Date().toLocaleDateString()}</p>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center text-gray-600">
                <FileText className="w-5 h-5 mr-2" />
                <span>No Reports Yet</span>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:col-span-2">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Account Details</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-500">Email</p>
                <p className="text-gray-900">{user?.email}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Phone</p>
                <p className="text-gray-900">{user?.phone || 'Not provided'}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Location</p>
                <p className="text-gray-900">{user?.location || 'Not provided'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Schedule Service</h3>
          <p className="text-gray-600 mb-4">Book your first vehicle inspection or service appointment.</p>
          <button
            onClick={() => navigate('/booking')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
          >
            Book Now
          </button>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Add Vehicle</h3>
          <p className="text-gray-600 mb-4">Register your vehicle to get personalized service recommendations.</p>
          <button
            onClick={() => navigate('/vehicles/add')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
          >
            Add Vehicle
          </button>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Complete Profile</h3>
          <p className="text-gray-600 mb-4">Update your profile information for better service.</p>
          <button
            onClick={() => setActiveTab('settings')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );

  const renderEmptyCalendar = () => (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Your Schedule</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Empty Appointments List */}
        <div className="lg:col-span-1">
          <div className="bg-white shadow-sm rounded-lg p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Upcoming Appointments</h3>
            <div className="text-center py-8">
              <CalendarDays className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No Appointments</h3>
              <p className="mt-1 text-sm text-gray-500">
                Schedule your first appointment to get started
              </p>
              <div className="mt-6">
                <button
                  onClick={() => navigate('/booking')}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700"
                >
                  Schedule Now
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Calendar */}
        <div className="lg:col-span-2">
          <div className="bg-white shadow-sm rounded-lg p-6">
            <Calendar
              appointments={[]}
              onSelectDate={(date) => {
                console.log('Selected date:', date);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return renderEmptyOverview();
      
      case 'scheduled':
        return renderEmptyCalendar();
      
      case 'reports':
        return renderEmptyState();
      
      case 'service-history':
        return (
          <div className="text-center py-12">
            <div className="mx-auto h-12 w-12 text-gray-400">
              <History className="h-12 w-12" />
            </div>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No Service History</h3>
            <p className="mt-1 text-sm text-gray-500">
              Your service history will appear here after your first service
            </p>
          </div>
        );
      
      case 'settings':
        return (
          <div className="space-y-8">
            {/* Profile Settings */}
            <div className="bg-white shadow-sm rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Profile Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    defaultValue={user?.name}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    defaultValue={user?.email}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    defaultValue={user?.phone}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
                <div>
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                    Location
                  </label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    defaultValue={user?.location}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500"
                  />
                </div>
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700"
                >
                  Save Changes
                </button>
              </div>
            </div>

            {/* Notification Preferences */}
            <div className="bg-white shadow-sm rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Notification Preferences</h3>
              <div className="space-y-4">
                {['Email', 'SMS', 'Marketing'].map((type) => (
                  <div key={type} className="flex items-center justify-between">
                    <div>
                      <h4 className="text-sm font-medium text-gray-900">{type} Notifications</h4>
                      <p className="text-sm text-gray-500">
                        {type === 'Email' && 'Receive updates about your appointments and services'}
                        {type === 'SMS' && 'Get text messages for appointment reminders'}
                        {type === 'Marketing' && 'Receive updates about special offers and promotions'}
                      </p>
                    </div>
                    <div className="flex h-6 items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Password Change */}
            <div className="bg-white shadow-sm rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Security</h3>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Change Password
              </button>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | C.A.R.S</title>
        <meta 
          name="description" 
          content="Access your C.A.R.S dashboard to view inspection reports, service history, and upcoming appointments."
        />
      </Helmet>
      <Navbar />
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Welcome Header */}
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Welcome back, {user.name}</h1>
            <p className="mt-2 text-gray-600">Manage your vehicle inspections and reports</p>
          </div>

          {/* Dashboard Navigation */}
          <div className="bg-white rounded-lg shadow-sm mb-8">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px">
                {DASHBOARD_TABS.map((tab) => {
                  const Icon = tab.icon;
                  return (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`
                        flex-1 px-4 py-4 text-center border-b-2 font-medium text-sm
                        ${activeTab === tab.id
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                        }
                      `}
                    >
                      <div className="flex items-center justify-center">
                        <Icon className="w-5 h-5 mr-2" />
                        {tab.name}
                      </div>
                    </button>
                  );
                })}
              </nav>
            </div>
          </div>

          {/* Dashboard Content */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
}
