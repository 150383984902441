import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

export default function ConfirmationPage() {
  const location = useLocation();
  const { paymentDetails } = location.state || {};

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow rounded-lg p-8 text-center"
        >
          <div className="flex justify-center mb-6">
            <CheckCircle className="h-16 w-16 text-green-500" />
          </div>
          
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Payment Successful!
          </h1>
          
          <p className="text-lg text-gray-600 mb-8">
            Thank you for your payment. Your transaction has been completed successfully.
          </p>

          {paymentDetails && (
            <div className="bg-gray-50 rounded-lg p-6 mb-8 text-left">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Transaction Details</h2>
              <div className="space-y-2">
                <p className="text-gray-600">
                  <span className="font-medium">Order ID:</span>{' '}
                  {paymentDetails.id}
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Status:</span>{' '}
                  {paymentDetails.status}
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Amount:</span>{' '}
                  ${paymentDetails.purchase_units[0].amount.value} USD
                </p>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <p className="text-gray-600">
              A confirmation email has been sent to your email address.
            </p>
            
            <div className="flex justify-center space-x-4">
              <Link
                to="/dashboard"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
              >
                Go to Dashboard
              </Link>
              <Link
                to="/"
                className="inline-flex items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Return Home
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
