import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import Navbar from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCamera, 
  faClipboardCheck, 
  faMagnifyingGlass, 
  faChartLine,
  faChevronDown,
  faChevronUp,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

export default function BookingCart() {
  const navigate = useNavigate();
  const { selectedPackage, addOns, addAddOn, removeAddOn } = useCart();
  const [openCategory, setOpenCategory] = useState<string | null>(null);

  const addonPrices = {
    'Photo Package': 49.99,
    'Video Documentation': 79.99,
    'Detailed Report': 39.99,
    'Emissions Test': 59.99,
    'Brake Test': 49.99,
    'Performance Analysis': 89.99,
    'Undercarriage Check': 69.99,
    'Paint Inspection': 49.99,
    'Interior Inspection': 39.99,
    'Computer Diagnostics': 79.99,
    'Fluid Analysis': 59.99,
    'Wear Pattern Analysis': 69.99
  };

  const handleAddonSelect = (addonName: string) => {
    // Check if addon already exists
    if (!addOns.some(addon => addon.name === addonName)) {
      const newAddon = {
        id: `addon-${Date.now()}`,
        name: addonName,
        price: addonPrices[addonName] || 0,
        type: 'addon' as const,
        category: getCategoryForAddon(addonName)
      };
      addAddOn(newAddon);
    }
    setOpenCategory(null); // Close dropdown after selection
  };

  const getCategoryForAddon = (addonName: string): 'documentation' | 'testing' | 'inspection' | 'analysis' => {
    if (['Photo Package', 'Video Documentation', 'Detailed Report'].includes(addonName)) {
      return 'documentation';
    } else if (['Emissions Test', 'Brake Test', 'Performance Analysis'].includes(addonName)) {
      return 'testing';
    } else if (['Undercarriage Check', 'Paint Inspection', 'Interior Inspection'].includes(addonName)) {
      return 'inspection';
    } else {
      return 'analysis';
    }
  };

  const totalPrice = (selectedPackage?.price || 0) + addOns.reduce((sum, addon) => sum + addon.price, 0);

  const handleProceedToBooking = () => {
    navigate('/payment', { state: { totalPrice } });
  };

  if (!selectedPackage) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <div className="pt-24 pb-16">
          <div className="max-w-4xl mx-auto px-4">
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h1 className="text-2xl font-bold text-gray-900 mb-4">No Service Selected</h1>
              <p className="text-gray-600 mb-4">Please select a service package before proceeding to booking.</p>
              <button
                onClick={() => navigate('/')}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
              >
                Browse Services
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-6">Your Selected Services</h1>

              {/* Main Package */}
              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Selected Package</h2>
                <div className="bg-gray-50 rounded-lg p-6 mb-4">
                  <div className="flex items-start justify-between">
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">{selectedPackage.name}</h3>
                      <p className="text-gray-600 mt-1">{selectedPackage.description}</p>
                    </div>
                    <div className="text-xl font-bold text-gray-900">${selectedPackage.price.toFixed(2)}</div>
                  </div>
                </div>

                {/* Addon Categories */}
                <h2 className="text-xl font-semibold mb-4">Available Add-ons</h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
                  {[
                    { id: 'documentation', icon: faCamera, title: 'Documentation', addons: ['Photo Package', 'Video Documentation', 'Detailed Report'] },
                    { id: 'testing', icon: faClipboardCheck, title: 'Testing', addons: ['Emissions Test', 'Brake Test', 'Performance Analysis'] },
                    { id: 'inspection', icon: faMagnifyingGlass, title: 'Inspection', addons: ['Undercarriage Check', 'Paint Inspection', 'Interior Inspection'] },
                    { id: 'analysis', icon: faChartLine, title: 'Analysis', addons: ['Computer Diagnostics', 'Fluid Analysis', 'Wear Pattern Analysis'] }
                  ].map((category) => (
                    <div key={category.id} className="relative">
                      <button
                        onClick={() => setOpenCategory(openCategory === category.id ? null : category.id)}
                        className="w-full flex flex-col items-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all"
                      >
                        <div className="bg-gray-100 rounded-full p-4 mb-2">
                          <FontAwesomeIcon icon={category.icon} className="text-3xl text-gray-700" />
                        </div>
                        <span className="text-sm font-medium">{category.title}</span>
                        <FontAwesomeIcon 
                          icon={openCategory === category.id ? faChevronUp : faChevronDown} 
                          className="text-gray-500 mt-2"
                        />
                      </button>
                      
                      {/* Dropdown Menu */}
                      {openCategory === category.id && (
                        <div className="absolute z-10 w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200">
                          {category.addons.map((addon) => (
                            <button
                              key={addon}
                              onClick={() => handleAddonSelect(addon)}
                              className="w-full text-left px-4 py-2 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg flex justify-between items-center"
                            >
                              <span>{addon}</span>
                              <span className="text-gray-600">${addonPrices[addon].toFixed(2)}</span>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Selected Add-ons */}
                {addOns.length > 0 && (
                  <div className="mt-8">
                    <h2 className="text-xl font-semibold mb-4">Selected Add-ons</h2>
                    <div className="space-y-3">
                      {addOns.map((addon) => (
                        <div key={addon.name} className="bg-gray-50 rounded-lg p-4 flex justify-between items-center">
                          <div className="flex items-center gap-3">
                            <div className="text-gray-900">{addon.name}</div>
                          </div>
                          <div className="flex items-center gap-4">
                            <div className="text-gray-900 font-semibold">${addon.price.toFixed(2)}</div>
                            <button
                              onClick={() => removeAddOn(addon.id)}
                              className="text-red-600 hover:text-red-700"
                            >
                              <FontAwesomeIcon icon={faTrash} className="text-lg" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Total */}
                <div className="mt-8 border-t pt-6">
                  <div className="flex justify-between items-center text-xl font-bold">
                    <span>Total Price:</span>
                    <span>${totalPrice.toFixed(2)}</span>
                  </div>
                </div>

                <button
                  onClick={handleProceedToBooking}
                  className="w-full mt-6 bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors text-lg font-semibold"
                >
                  Proceed to Booking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
