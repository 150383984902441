import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ServiceSlider from '../components/ServiceSlider';
import ServiceSection from '../components/ServiceSection';
import Footer from '../components/Footer';
import { Search, Wrench, Thermometer } from 'lucide-react';
import { prePurchasePackages, seasonalServices, diagnosticServices } from '../data/services';

export default function ServicesPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <ServiceSlider />
      
      <ServiceSection
        title="Pre-Purchase Inspections"
        description="Make informed decisions with our comprehensive inspection packages"
        icon={Search}
        services={prePurchasePackages}
      />

      <ServiceSection
        title="Seasonal Maintenance"
        description="Keep your vehicle running smoothly all year round"
        icon={Thermometer}
        services={seasonalServices}
        theme="dark"
      />

      <ServiceSection
        title="Diagnostic Services"
        description="Advanced diagnostics to identify and resolve complex issues"
        icon={Wrench}
        services={diagnosticServices}
        columns={3}
      />

      <Footer />
    </div>
  );
}