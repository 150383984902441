import { motion, AnimatePresence } from 'framer-motion';
import { Check, ArrowRight, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { trackUserEngagement } from '../services/analytics/enhancedAnalytics';
import { useClickTracking } from '../hooks/useClickTracking';

interface ServiceCardProps {
  title: string;
  price: string;
  features: string[];
  isSignature?: boolean;
  delay?: number;
  serviceType?: string;
  description?: string;
  details?: {
    description?: string;
    visualBody?: string;
    mechanical?: string;
    fluids?: string;
    tires?: string;
    checklist?: string;
    photos?: string;
    battery?: string;
  };
}

export default function ServiceCard({ 
  title, 
  price, 
  features, 
  isSignature, 
  delay = 0,
  serviceType = 'pre-purchase',
  description = '',
  details = {}
}: ServiceCardProps) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const trackClick = useClickTracking();

  useEffect(() => {
    trackUserEngagement.serviceView({
      name: title,
      price: price,
      category: 'service_card'
    });
  }, [title, price]);

  const handleBookNow = (e: React.MouseEvent<HTMLElement>) => {
    trackClick(e);
    trackUserEngagement.buttonClick('book-now', 'service_card');
    trackUserEngagement.serviceInteraction({
      name: title,
      price: price,
      category: 'service_card'
    }, 'book_now');
    
    navigate('/booking', { 
      state: { 
        serviceTitle: title, 
        serviceType: serviceType, 
        servicePrice: price 
      } 
    });
  };

  const handleLearnMore = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    trackClick(e);
    trackUserEngagement.buttonClick('learn-more', 'service_card');
    trackUserEngagement.serviceInteraction({
      name: title,
      price: price,
      category: 'service_card'
    }, 'learn_more');
    
    setShowModal(true);
  };

  const handleCloseModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    trackClick(e);
    trackUserEngagement.buttonClick('close-modal', 'service_card');
    setShowModal(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay }}
        className={`relative bg-white rounded-xl shadow-md hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ${
          isSignature ? 'border-2 border-red-600' : 'border border-gray-200'
        }`}
      >
        {isSignature && (
          <div className="absolute top-0 right-0 bg-red-600 text-white px-4 py-1 text-sm font-medium rounded-bl-lg">
            Most Popular
          </div>
        )}
        
        <div className="p-6">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
            <div className="mt-4 flex items-baseline text-gray-900">
              <span className="text-3xl font-bold text-gray-900">
                ${price.includes('/hr') 
                  ? `${parseFloat(price.split('/')[0]).toFixed(2)}/hr`
                  : parseFloat(price).toFixed(2)}
              </span>
            </div>
            {title === "Complete Condition Report" && (
              <p className="mt-2 text-sm font-medium text-red-600">
                Includes all services from Essential Check
              </p>
            )}
            {title === "C.A.R.S Signature Package" && (
              <p className="mt-2 text-sm font-medium text-red-600">
                Includes all services from Complete Condition Report
              </p>
            )}
            {title === "Elite Package" && (
              <p className="mt-2 text-sm font-medium text-red-600">
                Includes all services from C.A.R.S Signature Package
              </p>
            )}
            {title === "Essential" && (
              <p className="mt-2 text-sm font-medium text-blue-600">
                Budget Friendly Service
              </p>
            )}
          </div>
          <ul className="mt-6 space-y-4">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start gap-2">
                <Check className="h-5 w-5 text-red-600 flex-shrink-0 mt-0.5" />
                <span className="text-gray-600">{feature}</span>
              </li>
            ))}
          </ul>

          {title === "Elite Package" && (
            <div className="mt-6 mb-4 p-2 bg-yellow-50 border border-yellow-200 rounded-lg text-center">
              <p className="text-sm font-bold text-yellow-800">OUR MOST ADVANCED TEST</p>
            </div>
          )}

          <button
            onClick={handleLearnMore}
            className="mt-6 block w-full rounded-md bg-gray-100 px-3 py-2 text-center text-sm font-semibold text-gray-700 shadow-sm hover:shadow-md hover:-translate-y-0.5 transition-all duration-300"
          >
            Learn More
          </button>

          <button
            onClick={handleBookNow}
            className="mt-6 w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 shadow-md hover:shadow-lg hover:-translate-y-0.5 transition-all duration-300"
          >
            Book Now
            <ArrowRight className="ml-2 h-4 w-4" />
          </button>
        </div>
      </motion.div>

      {showModal && (
        <>
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={handleCloseModal}
          />
          <div 
            className="fixed inset-0 z-50 overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <div className="inline-block align-bottom bg-white/95 backdrop-blur-sm rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    onClick={handleCloseModal}
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-2xl leading-6 font-bold text-gray-900 mb-8">
                      {title} - Detailed Information
                    </h3>

                    {title === "Complete Condition Report" && (
                      <div className="mb-8 p-4 bg-red-50 border border-red-100 rounded-lg">
                        <h4 className="text-xl font-bold text-red-600 text-center">
                          INCLUDES ALL SERVICES FROM ESSENTIAL CHECK
                        </h4>
                      </div>
                    )}

                    {title === "C.A.R.S Signature Package" && (
                      <div className="mb-8 p-4 bg-red-50 border border-red-100 rounded-lg">
                        <h4 className="text-xl font-bold text-red-600 text-center">
                          INCLUDES ALL SERVICES FROM COMPLETE CONDITION REPORT
                        </h4>
                      </div>
                    )}

                    {details.description ? (
                      <p className="text-gray-600 text-lg leading-relaxed">{details.description}</p>
                    ) : (
                      <>
                        {details.visualBody && (
                          <div className="mb-6">
                            <h4 className="text-lg font-semibold text-gray-900 mb-2">
                              {title === "Complete Condition Report" ? "Basic Check Engine OBD2 Scan" : 
                               title === "C.A.R.S Signature Package" ? "Full System Diagnostic Scan" :
                               title === "Elite Package" ? "Factory-Level Testing" :
                               "Visual Body Condition Assessment"}
                            </h4>
                            <p className="text-gray-600">{details.visualBody}</p>
                          </div>
                        )}

                        {details.mechanical && (
                          <div className="mb-6">
                            <h4 className="text-lg font-semibold text-gray-900 mb-2">
                              Basic Mechanical Inspection
                            </h4>
                            <p className="text-gray-600">{details.mechanical}</p>
                          </div>
                        )}

                        {details.fluids && (
                          <div className="mb-6">
                            <h4 className="text-lg font-semibold text-gray-900 mb-2">
                              Fluid Level Checks
                            </h4>
                            <p className="text-gray-600">{details.fluids}</p>
                          </div>
                        )}

                        {details.tires && (
                          <div className="mb-6">
                            <h4 className="text-lg font-semibold text-gray-900 mb-2">
                              Tire Condition Check
                            </h4>
                            <p className="text-gray-600">{details.tires}</p>
                          </div>
                        )}

                        {details.checklist && (
                          <div className="mb-6">
                            <h4 className="text-lg font-semibold text-gray-900 mb-2">
                              {title === "Complete Condition Report" ? "100+ Point Checklist" :
                               title === "C.A.R.S Signature Package" ? "150+ Point Checklist" :
                               title === "Elite Package" ? "200+ Point Checklist" :
                               "50+ Point Checklist"}
                            </h4>
                            <p className="text-gray-600">{details.checklist}</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="mt-8 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={handleBookNow}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Book This Service
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}