import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { blogPosts } from '../data/blogPosts';

export default function Footer() {
  // Get the 3 most recent blog posts
  const latestPosts = [...blogPosts]
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 3);

  return (
    <motion.footer 
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="bg-neutral-900 text-white py-12 shadow-2xl"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <motion.div 
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="bg-neutral-800 p-6 rounded-xl shadow-lg"
          >
            <h3 className="text-xl font-bold mb-4 text-primary-500">About Us</h3>
            <p className="text-neutral-300">C.A.R.S Colorado Automotive Report & Service LLC</p>
            <p className="text-neutral-300">Denver, Colorado</p>
          </motion.div>

          {/* Contact Info */}
          <motion.div 
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="bg-neutral-800 p-6 rounded-xl shadow-lg"
          >
            <h3 className="text-xl font-bold mb-4 text-primary-500">Contact</h3>
            <div className="space-y-3">
              <motion.a 
                href="mailto:AFox@AutoReportServ.CO" 
                whileHover={{ x: 5 }}
                className="flex items-center text-neutral-300 hover:text-white transition-all"
              >
                <Mail className="w-6 h-6 mr-3 text-primary-500" />
                AFox@AutoReportServ.CO
              </motion.a>
              <motion.a 
                href="tel:+13039089815" 
                whileHover={{ x: 5 }}
                className="flex items-center text-neutral-300 hover:text-white transition-all"
              >
                <Phone className="w-6 h-6 mr-3 text-primary-500" />
                (303) 908-9815
              </motion.a>
              <div className="flex items-center text-neutral-300">
                <MapPin className="w-6 h-6 mr-3 text-primary-500" />
                Denver, Colorado
              </div>
            </div>
          </motion.div>

          {/* Blog Posts */}
          <motion.div 
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="bg-neutral-800 p-6 rounded-xl shadow-lg"
          >
            <h3 className="text-xl font-bold mb-4 text-primary-500">Latest Articles</h3>
            <ul className="space-y-3">
              {latestPosts.map((post) => (
                <motion.li 
                  key={post.slug}
                  whileHover={{ x: 10, scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Link 
                    to={`/blog/${post.slug}`} 
                    className="text-neutral-300 hover:text-white block transition-all duration-300"
                  >
                    {post.title}
                    <span className="text-sm text-neutral-400 block">
                      {new Date(post.date).toLocaleDateString('en-US', { 
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </span>
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Legal Links */}
          <motion.div 
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="bg-neutral-800 p-6 rounded-xl shadow-lg"
          >
            <h3 className="text-xl font-bold mb-4 text-primary-500">Legal</h3>
            <ul className="space-y-3">
              {[
                { name: 'Privacy Policy', path: '/privacy-policy' },
                { name: 'Terms & Conditions', path: '/terms-conditions' },
                { name: 'Accessibility Statement', path: '/accessibility' }
              ].map((link) => (
                <motion.li 
                  key={link.path}
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Link 
                    to={link.path} 
                    className="text-neutral-300 hover:text-white transition-all"
                  >
                    {link.name}
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="mt-12 pt-8 border-t border-neutral-800 text-center"
        >
          <p className="text-neutral-300">
            {new Date().getFullYear()} C.A.R.S Colorado Automotive Report & Service LLC. All rights reserved.
          </p>
        </motion.div>
      </div>
    </motion.footer>
  );
}
