import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const SLIDE_DURATION = 7000;

const slides = [
  {
    image: "https://i.ibb.co/tHYp39c/inspecttt.jpg",
    title: "Pre-Purchase Inspections",
    description: "Make confident buying decisions with our thorough vehicle inspections",
    link: "/services"
  },
  {
    image: "https://i.ibb.co/tMhrPMt/4-seasons.jpg",
    title: "Seasonal Maintenance",
    description: "Keep your vehicle in top condition throughout the year",
    link: "/services#seasonal"
  },
  {
    image: "https://i.ibb.co/MgfHSGz/diag.jpg",
    title: "Diagnostic Services",
    description: "Advanced diagnostics to identify and solve complex issues",
    link: "/services#diagnostic"
  }
];

export default function ServiceSlider() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [imageError, setImageError] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }, SLIDE_DURATION);
    return () => clearInterval(timer);
  }, []);

  const handleImageError = (index: number) => {
    setImageError(prev => ({ ...prev, [index]: true }));
  };

  return (
    <div className="relative h-[600px] overflow-hidden">
      <AnimatePresence mode="wait">
        {slides.map((slide, index) => (
          index === activeSlide && (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/40 z-10" />
              {!imageError[index] ? (
                <motion.img
                  initial={{ scale: 1.1 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 8 }}
                  src={slide.image}
                  alt={slide.title}
                  onError={() => handleImageError(index)}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-neutral-800 flex items-center justify-center">
                  <span className="text-neutral-200 text-lg">Image unavailable</span>
                </div>
              )}
              <div className="absolute inset-0 z-20 flex items-center">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl"
                  >
                    <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                      {slide.title}
                    </h1>
                    <p className="text-xl text-white/90 mb-6">{slide.description}</p>
                    <Link
                      to={slide.link}
                      className="inline-flex items-center gap-2 bg-primary-600 text-white 
                      px-6 py-3 rounded-lg hover:bg-primary-700 transition 
                      transform hover:scale-105 hover:shadow-3d-medium active:scale-95 duration-300"
                    >
                      Learn More
                      <ArrowRight className="w-5 h-5" />
                    </Link>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          )
        ))}
      </AnimatePresence>
      
      <div className="absolute bottom-8 left-1/2 -translate-x-1/2 z-30 flex gap-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              index === activeSlide 
                ? 'bg-primary-500 w-8' 
                : 'bg-white/50 hover:bg-white/80'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}