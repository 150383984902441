import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { toast } from 'react-toastify';

interface PayPalButtonProps {
  amount: number;
  onSuccess?: (details: any) => void;
  onError?: (error: any) => void;
}

export default function PayPalButton({ amount, onSuccess, onError }: PayPalButtonProps) {
  const navigate = useNavigate();
  const [{ isPending, isRejected }] = usePayPalScriptReducer();

  // Format amount to exactly 2 decimal places
  const formatAmount = (value: number): string => {
    return (Math.round(value * 100) / 100).toFixed(2);
  };

  const handleCreateOrder = (data: any, actions: any) => {
    try {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: formatAmount(amount),
              currency_code: "USD",
              breakdown: {
                item_total: {
                  currency_code: "USD",
                  value: formatAmount(amount)
                }
              }
            },
            description: "Car Detailing Services",
            items: [
              {
                name: "Car Detailing Services",
                quantity: "1",
                unit_amount: {
                  currency_code: "USD",
                  value: formatAmount(amount)
                }
              }
            ]
          }
        ],
        application_context: {
          brand_name: "Car Detailing Services",
          locale: "en-US",
          landing_page: "BILLING",
          shipping_preference: "NO_SHIPPING",
          user_action: "PAY_NOW",
          return_url: window.location.origin + "/confirmation",
          cancel_url: window.location.origin + "/cart"
        }
      });
    } catch (error) {
      handlePayPalError(error);
      throw error;
    }
  };

  const handleApprove = async (data: any, actions: any) => {
    try {
      const details = await actions.order.capture();
      console.log("Payment completed successfully:", details);
      
      if (onSuccess) {
        onSuccess(details);
      }

      toast.success("Payment completed successfully!");
      
      navigate("/confirmation", {
        state: {
          paymentDetails: details,
          paymentMethod: details.payment_source?.card ? "card" : "paypal",
          orderId: details.id,
          amount: formatAmount(amount)
        }
      });
    } catch (error) {
      handlePayPalError(error);
      throw error;
    }
  };

  const handlePayPalError = (error: any) => {
    console.error('PayPal Error:', error);
    
    // Specific handling for country mismatch
    if (error.message === 'GUEST_CARD_COUNTRY_MISMATCH') {
      toast.error('Payment method not supported in your country. Please use an alternative payment method or contact support.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error('An error occurred during payment processing. Please try again.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleError = (err: any) => {
    handlePayPalError(err);
    if (onError) {
      onError(err);
    }
  };

  if (isPending) {
    return (
      <div className="text-center py-4">
        <div className="animate-pulse">Loading payment options...</div>
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="text-center text-red-600 py-4">
        Payment system failed to load. Please refresh the page or try again later.
      </div>
    );
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <PayPalButtons
        style={{
          layout: "vertical",
          shape: "rect",
          color: "gold"
        }}
        createOrder={handleCreateOrder}
        onApprove={handleApprove}
        onError={handleError}
        onCancel={() => {
          toast.info("Payment cancelled");
          navigate("/cart");
        }}
      />
      <div className="text-sm text-gray-500 mt-4 text-center">
        <p>We accept US-issued credit/debit cards and PayPal.</p>
        <p className="mt-2">By proceeding with the payment, you agree to our terms and conditions.</p>
      </div>
    </div>
  );
}
