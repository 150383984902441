import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAdmin } from '../../contexts/AdminContext';
import { storage, firestore } from '../../config/firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, serverTimestamp, doc, setDoc, deleteDoc, onSnapshot, query, orderBy } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

interface BlogPost {
  id: string;
  title: string;
  url: string;
  imageUrls: string[];
  createdAt: any;
}

export default function AdminDashboard() {
  const navigate = useNavigate();
  const { isAdmin, loading } = useAdmin();
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [previews, setPreviews] = useState<string[]>([]);
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingPost, setEditingPost] = useState<BlogPost | null>(null);

  useEffect(() => {
    if (!loading && !isAdmin) {
      navigate('/login');
    }
  }, [isAdmin, loading, navigate]);

  useEffect(() => {
    const blogPostsRef = collection(firestore, 'blogPosts');
    const q = query(blogPostsRef, orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      } as BlogPost));
      setBlogPosts(data);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length > 8) {
      toast.error('Please select up to 8 images only');
      return;
    }

    setImages(files);
    setPreviews(files.map(file => URL.createObjectURL(file)));
  };

  const validateUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      // Create a unique filename
      const timestamp = Date.now();
      const safeFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
      const fileRef = ref(storage, `blog-images/${timestamp}-${safeFileName}`);

      // Upload settings with proper metadata
      const metadata = {
        contentType: file.type,
        cacheControl: 'public, max-age=31536000',
        customMetadata: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Content-Type'
        }
      };

      // Upload the file directly with metadata
      const snapshot = await uploadBytes(fileRef, file, metadata);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateUrl(url)) {
      toast.error('Please enter a valid URL');
      return;
    }

    if (images.length === 0 && !editingPost) {
      toast.error('Please select at least one image');
      return;
    }

    if (images.length > 8) {
      toast.error('Please select up to 8 images only');
      return;
    }

    setIsSubmitting(true);

    try {
      // Upload images first
      const uploadedImageUrls = editingPost?.imageUrls || [];
      
      if (images.length > 0) {
        const newImageUrls = await Promise.all(
          images.map(async (image) => {
            try {
              return await handleImageUpload(image);
            } catch (error) {
              console.error('Error uploading image:', error);
              return null;
            }
          })
        );

        // Filter out any failed uploads
        const successfulUploads = newImageUrls.filter((url): url is string => url !== null);
        uploadedImageUrls.push(...successfulUploads);
      }

      // Create/Update blog post
      const blogPostData = {
        title,
        url,
        imageUrls: uploadedImageUrls,
        ...(editingPost 
          ? { 
              createdAt: editingPost.createdAt,
              updatedAt: serverTimestamp()
            }
          : { 
              createdAt: serverTimestamp()
            }
        )
      };

      if (editingPost) {
        await setDoc(doc(firestore, 'blogPosts', editingPost.id), blogPostData);
        toast.success('Blog post updated successfully!');
      } else {
        await addDoc(collection(firestore, 'blogPosts'), blogPostData);
        toast.success('Blog post created successfully!');
      }

      // Reset form
      setTitle('');
      setUrl('');
      setImages([]);
      setPreviews([]);
      setEditingPost(null);
    } catch (error) {
      console.error('Error saving blog post:', error);
      toast.error('Failed to save blog post. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (post: BlogPost) => {
    setEditingPost(post);
    setTitle(post.title);
    setUrl(post.url);
    setPreviews(post.imageUrls);
    setImages([]);
    window.scrollTo(0, 0);
  };

  const handleDelete = async (post: BlogPost) => {
    if (!window.confirm('Are you sure you want to delete this blog post?')) {
      return;
    }

    try {
      // Delete images from storage
      await Promise.all(
        post.imageUrls.map(async (imageUrl) => {
          try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('Error deleting image:', error);
          }
        })
      );

      // Delete post from Firestore
      await deleteDoc(doc(firestore, 'blogPosts', post.id));
      toast.success('Blog post deleted successfully!');
    } catch (error) {
      console.error('Error deleting blog post:', error);
      toast.error('Failed to delete blog post. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Admin Dashboard - Blog Management</title>
      </Helmet>

      <h1 className="text-3xl font-bold mb-8">
        {editingPost ? 'Edit Blog Post' : 'Create New Blog Post'}
      </h1>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Title
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            URL
          </label>
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Images (Max 8)
          </label>
          <input
            type="file"
            onChange={handleImageChange}
            multiple
            accept="image/*"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {previews.length > 0 && (
          <div className="grid grid-cols-4 gap-4 mb-4">
            {previews.map((preview, index) => (
              <img
                key={index}
                src={preview}
                alt={`Preview ${index + 1}`}
                className="w-full h-32 object-cover rounded"
              />
            ))}
          </div>
        )}

        <button
          type="submit"
          disabled={isSubmitting}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting
            ? 'Saving...'
            : editingPost
            ? 'Update Blog Post'
            : 'Create Blog Post'}
        </button>
      </form>

      <h2 className="text-2xl font-bold mb-4">Existing Blog Posts</h2>

      {isLoading ? (
        <div>Loading blog posts...</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {blogPosts.map((post) => (
            <div
              key={post.id}
              className="border rounded-lg overflow-hidden shadow-lg"
            >
              {post.imageUrls[0] && (
                <img
                  src={post.imageUrls[0]}
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-4">
                <h3 className="font-bold text-xl mb-2">{post.title}</h3>
                <a
                  href={post.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700"
                >
                  View Post
                </a>
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    onClick={() => handleEdit(post)}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(post)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
