import { useEffect, useRef } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { Car, Award, FileText, Clock, Cpu, Heart } from 'lucide-react';

const features = [
  {
    title: "Mobile Service",
    description: "We come to you - home, work, or anywhere convenient",
    icon: Car,
    color: "bg-neutral-100 text-primary-600"
  },
  {
    title: "Certified Experts",
    description: "ASE certified technicians with years of experience",
    icon: Award,
    color: "bg-neutral-100 text-primary-600"
  },
  {
    title: "Comprehensive Reports",
    description: "Detailed digital reports with photos and explanations",
    icon: FileText,
    color: "bg-neutral-100 text-primary-600"
  },
  {
    title: "Time Efficient",
    description: "Quick turnaround without compromising quality",
    icon: Clock,
    color: "bg-neutral-100 text-primary-600"
  },
  {
    title: "Advanced Technology",
    description: "State-of-the-art diagnostic equipment",
    icon: Cpu,
    color: "bg-neutral-100 text-primary-600"
  },
  {
    title: "Customer First",
    description: "Transparent, honest, and professional service",
    icon: Heart,
    color: "bg-neutral-100 text-primary-600"
  }
];

export default function ScrollingBanner() {
  const controls = useAnimationControls();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const startScrolling = async () => {
      if (!containerRef.current) return;
      
      const containerWidth = containerRef.current.scrollWidth;
      const viewportWidth = containerRef.current.offsetWidth;
      
      await controls.start({
        x: [0, -(containerWidth - viewportWidth)],
        transition: {
          duration: 40,
          ease: "linear",
          repeat: Infinity,
        }
      });
    };

    startScrolling();
  }, [controls]);

  return (
    <div className="w-full bg-neutral-900 py-6 overflow-hidden">
      <div ref={containerRef} className="relative w-full">
        <motion.div 
          animate={controls}
          className="flex space-x-8 whitespace-nowrap px-4"
        >
          {features.concat(features).map((feature, index) => {
            const Icon = feature.icon;
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ 
                  duration: 0.5, 
                  type: "spring", 
                  stiffness: 300 
                }}
                whileHover={{ scale: 1.05 }}
                className={`inline-flex items-center ${feature.color} rounded-xl px-6 py-4 shadow-2xl border border-neutral-700/20 backdrop-blur-sm`}
              >
                <Icon className="h-8 w-8 mr-4 flex-shrink-0 text-primary-500" />
                <div>
                  <h3 className="text-lg font-semibold text-neutral-900">{feature.title}</h3>
                  <p className="text-sm text-neutral-700 opacity-90">{feature.description}</p>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
}
