import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Wrench } from 'lucide-react';
import { diagnosticServices } from '../data/services';

export default function DiagnosticServices() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Diagnostic Services"
          description="Advanced diagnostics to identify and resolve complex issues"
          icon={Wrench}
          services={diagnosticServices}
          columns={3}
        />
      </div>
    </div>
  );
}