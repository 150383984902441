import { z } from 'zod';

export const bookingFormSchema = z.object({
  from_name: z.string()
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be less than 50 characters'),
  phone: z.string()
    .regex(/^\+?[1-9]\d{1,14}$/, 'Please enter a valid phone number'),
  from_email: z.string()
    .email('Please enter a valid email address'),
  vehicleLink: z.string()
    .url('Please enter a valid URL')
    .optional()
    .or(z.literal('')),
  location: z.string()
    .min(5, 'Please enter a valid location')
    .max(100, 'Location must be less than 100 characters'),
  sellerName: z.string()
    .min(2, 'Seller name must be at least 2 characters')
    .max(50, 'Seller name must be less than 50 characters')
    .optional()
    .or(z.literal('')),
  sellerPhone: z.string()
    .regex(/^\+?[1-9]\d{1,14}$/, 'Please enter a valid phone number')
    .optional()
    .or(z.literal('')),
  notes: z.string()
    .max(500, 'Notes must be less than 500 characters')
    .optional()
    .or(z.literal('')),
  preferredDate: z.date()
    .min(new Date(), 'Please select a future date')
    .optional(),
  preferredTime: z.string()
    .regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Please enter a valid time')
    .optional(),
});

export type BookingFormData = z.infer<typeof bookingFormSchema>;

export const validateBookingForm = (data: Partial<BookingFormData>) => {
  try {
    bookingFormSchema.parse(data);
    return { success: true, errors: null };
  } catch (error) {
    if (error instanceof z.ZodError) {
      const errors = error.errors.reduce((acc, curr) => ({
        ...acc,
        [curr.path[0]]: curr.message,
      }), {});
      return { success: false, errors };
    }
    return { success: false, errors: { form: 'Invalid form data' } };
  }
};

export function validateServicePackage(pkg: ServicePackage) {
  try {
    servicePackageSchema.parse(pkg);
    return { success: true, errors: null };
  } catch (error) {
    if (error instanceof z.ZodError) {
      const validationError = ValidationError.fromZodError(error);
      logValidationError(validationError);
      return { 
        success: false, 
        errors: validationError.details 
      };
    }
    return { 
      success: false, 
      errors: { general: 'Unexpected validation error' } 
    };
  }
};

export const validateAddons = (addons: Addon[]) => {
  try {
    const validatedAddons = addons.map(addon => addonSchema.parse(addon));
    return { success: true, errors: null, validatedAddons };
  } catch (error) {
    if (error instanceof z.ZodError) {
      const validationError = ValidationError.fromZodError(error);
      logValidationError(validationError);
      return { 
        success: false, 
        errors: validationError.details 
      };
    }
    return { 
      success: false, 
      errors: { general: 'Unexpected addon validation error' } 
    };
  }
};

export const validateServiceType = (type: string): type is ServiceType => 
  ['pre-purchase', 'seasonal', 'diagnostic'].includes(type);
