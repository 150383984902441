import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Car, Calendar, Phone, Clipboard, FileCheck } from 'lucide-react';

interface Step {
  id: number;
  title: string;
  description: string;
  icon: JSX.Element;
}

export default function Process() {
  const [openStep, setOpenStep] = useState<number | null>(0);

  const steps: Step[] = [
    {
      id: 0,
      title: "Vehicle Selection",
      description: "Identify the vehicle you're interested in and select the ideal inspection package through our streamlined online platform.",
      icon: <Car className="w-10 h-10 stroke-[1.5]" />
    },
    {
      id: 1,
      title: "Booking Inspection",
      description: "Schedule your preferred inspection package online. Our support team is ready to assist you with any questions or concerns.",
      icon: <Calendar className="w-10 h-10 stroke-[1.5]" />
    },
    {
      id: 2,
      title: "Seller Coordination",
      description: "We manage all communication with the seller, arranging the inspection time and providing you with prompt confirmation.",
      icon: <Phone className="w-10 h-10 stroke-[1.5]" />
    },
    {
      id: 3,
      title: "Detailed Inspection",
      description: "Our certified technicians conduct a comprehensive vehicle inspection, meticulously documenting every critical aspect of the vehicle's condition.",
      icon: <Clipboard className="w-10 h-10 stroke-[1.5]" />
    },
    {
      id: 4,
      title: "Expert Report",
      description: "Receive a comprehensive, professional report within 24 hours, empowering you to make an informed purchasing decision.",
      icon: <FileCheck className="w-10 h-10 stroke-[1.5]" />
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <hr className="border-t-2 border-gray-200" />
      </div>
      <section id="process" className="py-24 bg-gray-50">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold text-gray-900">Our Inspection Process</h2>
            <p className="mt-4 text-xl text-gray-700">
              Systematic, Professional Vehicle Inspection Workflow
            </p>
          </motion.div>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="space-y-6"
          >
            {steps.map((step) => (
              <motion.div
                key={step.id}
                variants={itemVariants}
                className="bg-white border border-gray-200 rounded-xl shadow-md overflow-hidden"
              >
                <motion.button
                  onClick={() => setOpenStep(openStep === step.id ? null : step.id)}
                  className="w-full px-6 py-5 flex items-center justify-between bg-white hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center gap-6">
                    <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center text-gray-700">
                      {step.icon}
                    </div>
                    <div className="text-left">
                      <span className="text-sm text-gray-600 font-medium">Step {step.id + 1}</span>
                      <h3 className="text-xl font-semibold text-gray-900">{step.title}</h3>
                    </div>
                  </div>
                  <motion.div
                    animate={{ rotate: openStep === step.id ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ChevronDown className="w-6 h-6 text-gray-500" />
                  </motion.div>
                </motion.button>

                <motion.div
                  initial={false}
                  animate={{
                    height: openStep === step.id ? "auto" : 0,
                    opacity: openStep === step.id ? 1 : 0
                  }}
                  transition={{ duration: 0.3 }}
                  className="border-t border-gray-200 overflow-hidden"
                >
                  <div className="px-6 py-5 bg-white">
                    <p className="text-gray-700 text-base">{step.description}</p>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="mt-16 text-center"
          >
            <Link to="/contact">
              <button className="bg-gray-900 text-white px-10 py-4 text-lg rounded-xl hover:bg-gray-800 transition-colors shadow-lg">
                Contact Our Team
              </button>
            </Link>
          </motion.div>
        </div>
      </section>
    </>
  );
}