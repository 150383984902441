import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronRight, Check } from 'lucide-react';
import Navbar from '../components/Navbar';
import { prePurchasePackages, seasonalServices, diagnosticServices } from '../data/services';
import { useCart } from '../context/CartContext';

const availableAddons = [
  {
    id: '1',
    name: 'Brake Fluid Change',
    price: 89,
    type: 'addon',
    category: 'inspection'
  },
  {
    id: '2',
    name: 'Oil Change',
    price: 79,
    type: 'addon',
    category: 'inspection'
  },
  {
    id: '3',
    name: 'Tire Rotation',
    price: 59,
    type: 'addon',
    category: 'inspection'
  }
];

export default function PackageDetails() {
  const { id, serviceType } = useParams();
  const navigate = useNavigate();
  const { setPackage, addOns, addAddOn, removeAddOn } = useCart();

  const getPackagesByType = (type: string) => {
    switch (type) {
      case 'pre-purchase': return prePurchasePackages;
      case 'seasonal': return seasonalServices;
      case 'diagnostic': return diagnosticServices;
      default: return [];
    }
  };

  const findPackage = (id: string, type: string) => {
    const packages = getPackagesByType(type);
    return packages.find(p => {
      const packageSlug = p.title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '')
        .replace(/\.+/g, '-');
      return packageSlug === id;
    });
  };

  const selectedPackage = findPackage(id, serviceType);

  if (!selectedPackage) {
    return <div>Package not found</div>;
  }

  const handleContinueToBooking = () => {
    try {
      console.group('Booking Process');
      console.log('Continue to Booking clicked');
      console.log('Selected Package:', selectedPackage);
      console.log('Current Add-ons:', addOns);
      
      if (!selectedPackage) {
        console.error('No package selected');
        alert('Please select a service package before continuing');
        return;
      }

      // Set the selected package in the cart context
      setPackage({
        id: selectedPackage.id || id,
        name: selectedPackage.title,
        price: selectedPackage.price,
        description: selectedPackage.description,
        features: selectedPackage.features,
        type: 'package'
      });
      
      console.log('Navigating to booking cart...');
      // Navigate to the booking cart page
      navigate('/booking-cart');
      console.groupEnd();
    } catch (error) {
      console.error('Error in booking process:', error);
      alert('An error occurred while processing your booking. Please try again.');
    }
  };

  const toggleAddon = (addon: any) => {
    const isSelected = addOns.some(a => a.id === addon.id);
    console.log(`Toggling addon: ${addon.name}, currently selected: ${isSelected}`);
    if (isSelected) {
      console.log(`Removing addon: ${addon.name}`);
      removeAddOn(addon.id);
    } else {
      console.log(`Adding addon: ${addon.name}`);
      addAddOn(addon);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8">
              <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">{selectedPackage.title}</h1>
                <p className="text-gray-600 mb-6">{selectedPackage.description}</p>
                <div className="bg-gray-50 rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-semibold text-gray-900">Service Features</h2>
                    <span className="text-2xl font-bold text-blue-600">${selectedPackage.price}</span>
                  </div>
                  <ul className="space-y-3">
                    {selectedPackage.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-700">
                        <Check className="w-5 h-5 text-green-500 mr-3" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Available Add-ons</h2>
                <div className="space-y-4">
                  {availableAddons.map(addon => (
                    <div
                      key={addon.id}
                      className={`border rounded-lg p-4 cursor-pointer transition-colors ${
                        addOns.some(a => a.id === addon.id)
                          ? 'border-blue-500 bg-blue-50'
                          : 'hover:border-gray-300'
                      }`}
                      onClick={() => toggleAddon(addon)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className={`w-5 h-5 rounded-full border flex items-center justify-center mr-3 ${
                            addOns.some(a => a.id === addon.id)
                              ? 'border-blue-500 bg-blue-500'
                              : 'border-gray-300'
                          }`}>
                            {addOns.some(a => a.id === addon.id) && (
                              <Check className="w-4 h-4 text-white" />
                            )}
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900">{addon.name}</h3>
                          </div>
                        </div>
                        <span className="font-medium text-gray-900">${addon.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="border-t pt-6">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-medium text-gray-900">Total</span>
                  <span className="text-2xl font-bold text-blue-600">
                    ${selectedPackage.price + addOns.reduce((sum, addon) => sum + addon.price, 0)}
                  </span>
                </div>
                <button
                  onClick={handleContinueToBooking}
                  className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center justify-center"
                >
                  Continue to Booking
                  <ChevronRight className="w-5 h-5 ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}