import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';
import ServiceCard from './ServiceCard';

interface ServiceSectionProps {
  title: string;
  description: string;
  icon: LucideIcon;
  services: Array<{
    title: string;
    price: string;
    features: string[];
    details?: {
      visualBody?: string;
      mechanical?: string;
      fluids?: string;
      tires?: string;
      checklist?: string;
    };
    isSignature?: boolean;
  }>;
  theme?: 'light' | 'dark';
  columns?: 3 | 4;
  serviceType?: string;
}

export default function ServiceSection({ 
  title, 
  description, 
  icon: Icon,
  services,
  theme = 'light',
  columns = 4,
  serviceType = 'pre-purchase'
}: ServiceSectionProps) {
  const isDark = theme === 'dark';

  return (
    <section className={`py-24 ${isDark ? 'bg-gray-900 mountain-pattern' : ''}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center gap-4 mb-12">
          <Icon className={`w-8 h-8 ${isDark ? 'text-red-500' : 'text-red-600'}`} />
          <div>
            <h2 className={`text-3xl font-bold ${isDark ? 'text-white' : 'text-gray-900'}`}>
              {title}
            </h2>
            <p className={`mt-2 text-lg ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
              {description}
            </p>
          </div>
        </div>
        <div className={`grid md:grid-cols-2 ${
          columns === 3 ? 'lg:grid-cols-3' : 
          title === "Diagnostic Services" ? 'lg:grid-cols-2 max-w-4xl mx-auto' : 
          'lg:grid-cols-4'
        } gap-8`}>
          {services.map((service, index) => (
            <ServiceCard 
              key={index} 
              {...service} 
              serviceType={serviceType}
              isDark={isDark}
            />
          ))}
        </div>
      </div>
    </section>
  );
}