import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Droplet } from 'lucide-react';

const oilServices = [
  {
    title: 'Basic Oil Analysis',
    price: '49.99',
    features: [
      'Viscosity testing',
      'Contamination check',
      'Basic wear analysis',
      'Oil condition report',
      'Basic recommendations',
      'Digital results'
    ]
  },
  {
    title: 'Advanced Oil Analysis',
    price: '89.99',
    features: [
      'Full spectrum analysis',
      'Metal content testing',
      'Additive analysis',
      'Detailed wear patterns',
      'Comprehensive report',
      'Expert consultation'
    ]
  },
  {
    title: 'Lab Oil Analysis',
    price: '149.99',
    features: [
      'Laboratory testing',
      'Particle analysis',
      'Oxidation testing',
      'TBN/TAN testing',
      'Trend analysis',
      'Technical consultation'
    ],
    isSignature: true
  }
];

export default function OilAnalysis() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Oil Analysis Services"
          description="Scientific oil analysis for optimal engine health"
          icon={Droplet}
          services={oilServices}
          columns={3}
        />
      </div>
    </div>
  );
}