import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { blogPosts } from '../data/blogPosts';
import { ChevronLeft } from 'lucide-react';

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  
  const post = blogPosts.find((p) => p.slug === slug);

  if (!post) {
    React.useEffect(() => {
      navigate('/blog');
    }, [navigate]);
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`${post.title} - C.A.R.S Colorado Automotive Report & Service`}</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <Link 
            to="/blog"
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8"
          >
            <ChevronLeft className="w-5 h-5 mr-1" />
            Back to Blog
          </Link>

          <article>
            <div className="mb-8">
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-64 object-cover rounded-lg shadow-lg"
              />
            </div>

            <div className="prose prose-lg max-w-none">
              <div className="flex items-center text-sm text-gray-500 mb-4">
                <span>{post.date}</span>
                <span className="mx-2">•</span>
                <span>{post.readTime}</span>
                <span className="mx-2">•</span>
                <span>By {post.author}</span>
              </div>

              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                {post.title}
              </h1>

              <div className="flex flex-wrap gap-2 mb-8">
                {post.tags.map((tag) => (
                  <span
                    key={tag}
                    className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-600"
                  >
                    {tag}
                  </span>
                ))}
              </div>

              <ReactMarkdown className="prose prose-lg max-w-none">
                {post.content}
              </ReactMarkdown>
            </div>
          </article>

          <div className="mt-12 pt-8 border-t border-gray-200">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Ready for a Professional Inspection?
            </h2>
            <p className="text-gray-600 mb-6">
              Don't take chances with your next vehicle purchase. Let our experts perform a thorough inspection to ensure you're making a sound investment.
            </p>
            <Link
              to="/contact"
              className="inline-block bg-red-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-red-700 transition duration-150 ease-in-out"
            >
              Schedule an Inspection
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
