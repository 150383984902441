import Navbar from '../components/Navbar';
import ServiceSection from '../components/ServiceSection';
import { Thermometer } from 'lucide-react';
import { seasonalServices } from '../data/services';

export default function SeasonalServices() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24">
        <ServiceSection
          title="Seasonal Maintenance"
          description="Keep your vehicle running smoothly all year round"
          icon={Thermometer}
          services={seasonalServices}
        />
      </div>
    </div>
  );
}