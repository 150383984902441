import React, { createContext, useContext, useState, useEffect } from 'react';

export interface AddOn {
  id: string;
  name: string;
  price: number;
  type: 'addon';
  category: 'documentation' | 'testing' | 'inspection' | 'analysis';
}

export interface ServicePackage {
  id: string;
  name: string;
  price: number;
  description: string;
  features: string[];
  type: 'pre-purchase' | 'seasonal' | 'diagnostic';
  title?: string;
}

export interface Vehicle {
  id: string;
  make: string;
  model: string;
  year: number;
  vin: string;
  mileage: number;
}

interface CartContextType {
  addOns: AddOn[];
  selectedPackage: ServicePackage | null;
  selectedVehicle: Vehicle | null;
  total: number;
  addAddOn: (addon: AddOn) => void;
  removeAddOn: (addonId: string) => void;
  setPackage: (pkg: ServicePackage | null) => void;
  setSelectedVehicle: (vehicle: Vehicle | null) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

// Load state from localStorage
const loadState = () => {
  try {
    const addOns = localStorage.getItem('addOns');
    const selectedPackage = localStorage.getItem('selectedPackage');
    const selectedVehicle = localStorage.getItem('selectedVehicle');
    
    return {
      addOns: addOns ? JSON.parse(addOns) : [],
      selectedPackage: selectedPackage ? JSON.parse(selectedPackage) : null,
      selectedVehicle: selectedVehicle ? JSON.parse(selectedVehicle) : null,
    };
  } catch (err) {
    console.error('Error loading state:', err);
    return {
      addOns: [],
      selectedPackage: null,
      selectedVehicle: null,
    };
  }
};

export function CartProvider({ children }: { children: React.ReactNode }) {
  const initialState = loadState();
  const [addOns, setAddOns] = useState<AddOn[]>(initialState.addOns);
  const [selectedPackage, setSelectedPackage] = useState<ServicePackage | null>({
    id: 'test-service',
    name: 'Test Service Package',
    price: 99.99,
    description: 'Test service for payment verification',
    features: ['Feature 1', 'Feature 2'],
    type: 'pre-purchase'
  });
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(initialState.selectedVehicle);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('addOns', JSON.stringify(addOns));
    localStorage.setItem('selectedPackage', JSON.stringify(selectedPackage));
    localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
  }, [addOns, selectedPackage, selectedVehicle]);

  const addAddOn = (addon: AddOn) => {
    setAddOns(prev => [...prev, addon]);
  };

  const removeAddOn = (addonId: string) => {
    setAddOns(prev => prev.filter(addon => addon.id !== addonId));
  };

  const setPackage = (pkg: ServicePackage | null) => {
    setSelectedPackage(pkg);
  };

  const total = (selectedPackage?.price || 0) + addOns.reduce((sum, addon) => sum + addon.price, 0);

  return (
    <CartContext.Provider value={{
      addOns,
      selectedPackage,
      selectedVehicle,
      total,
      addAddOn,
      removeAddOn,
      setPackage,
      setSelectedVehicle,
    }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}
