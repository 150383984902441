import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { v4 as uuidv4 } from 'uuid';

// Define event categories
export enum EventCategory {
  SERVICE = 'service',
  BOOKING = 'booking',
  NAVIGATION = 'navigation',
  ENGAGEMENT = 'engagement',
  ERROR = 'error'
}

// Define event names
export enum EventName {
  SERVICE_VIEW = 'service_view',
  SERVICE_CLICK = 'service_click',
  BOOKING_START = 'booking_start',
  BOOKING_COMPLETE = 'booking_complete',
  BOOKING_ABANDON = 'booking_abandon',
  PAGE_VIEW = 'page_view',
  BUTTON_CLICK = 'button_click',
  FORM_START = 'form_start',
  FORM_COMPLETE = 'form_complete',
  ERROR_OCCUR = 'error_occur'
}

class AnalyticsService {
  private sessionId: string;
  private pageLoadTime: number;
  private lastPageUrl: string | null;
  private interactions: {
    clicks: number;
    formInteractions: number;
    scrollDepth: number;
    timeOnPage: number;
  };
  private performanceMetrics: {
    loadTime: number;
    firstPaint: number;
    domInteractive: number;
    domComplete: number;
  };

  constructor() {
    this.sessionId = uuidv4();
    this.pageLoadTime = Date.now();
    this.lastPageUrl = null;
    this.interactions = {
      clicks: 0,
      formInteractions: 0,
      scrollDepth: 0,
      timeOnPage: 0,
    };
    this.performanceMetrics = {
      loadTime: 0,
      firstPaint: 0,
      domInteractive: 0,
      domComplete: 0,
    };

    this.setupEventListeners();
    this.initializeServices();
  }

  private setupEventListeners(): void {
    // Track clicks
    document.addEventListener('click', () => {
      this.interactions.clicks++;
    });

    // Track form interactions
    document.addEventListener('input', (e) => {
      if (e.target instanceof HTMLInputElement || 
          e.target instanceof HTMLTextAreaElement || 
          e.target instanceof HTMLSelectElement) {
        this.interactions.formInteractions++;
      }
    });

    // Track scroll depth
    window.addEventListener('scroll', () => {
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = window.scrollY;
      this.interactions.scrollDepth = Math.round((scrolled / scrollHeight) * 100);
    });

    // Track performance metrics
    window.addEventListener('load', () => {
      if (window.performance) {
        const perf = window.performance;
        const timing = perf.timing;
        
        this.performanceMetrics = {
          loadTime: timing.loadEventEnd - timing.navigationStart,
          firstPaint: timing.responseStart - timing.navigationStart,
          domInteractive: timing.domInteractive - timing.navigationStart,
          domComplete: timing.domComplete - timing.navigationStart,
        };
      }
    });
  }

  private initializeServices(): void {
    // In development mode, we'll just use console logging
    console.log('[Analytics] Initialized in development mode');
  }

  private getDeviceInfo() {
    return {
      screen_resolution: `${window.screen.width}x${window.screen.height}`,
      language: navigator.language,
      connection_type: (navigator as any).connection?.effectiveType || 'unknown',
      user_agent: navigator.userAgent,
      platform: navigator.platform,
    };
  }

  private async trackEvent(eventName: EventName, category: EventCategory, properties: any = {}): Promise<void> {
    const eventData = {
      session_id: this.sessionId,
      timestamp: new Date().toISOString(),
      event_category: category,
      page_url: window.location.href,
      referrer: this.lastPageUrl,
      time_on_page: Date.now() - this.pageLoadTime,
      scroll_depth: this.interactions.scrollDepth,
      click_count: this.interactions.clicks,
      form_interactions: this.interactions.formInteractions,
      ...this.getDeviceInfo(),
      ...this.performanceMetrics,
      ...properties
    };

    // In development, just log to console
    console.log('[Analytics Event]', {
      event: eventName,
      properties: eventData,
    });

    // Update last page URL
    this.lastPageUrl = window.location.href;
  }

  // Public tracking methods
  public trackServiceView(service: any): void {
    this.trackEvent(EventName.SERVICE_VIEW, EventCategory.SERVICE, {
      service_name: service.name,
      service_price: service.price,
      service_type: service.type || 'standard',
      service_duration: service.duration,
      service_description: service.description,
    });
  }

  public trackBookingStart(service: any): void {
    this.trackEvent(EventName.BOOKING_START, EventCategory.BOOKING, {
      service_name: service.name,
      service_price: service.price,
      service_type: service.type || 'standard',
    });
  }

  public trackBookingComplete(bookingData: any): void {
    this.trackEvent(EventName.BOOKING_COMPLETE, EventCategory.BOOKING, {
      ...bookingData,
      conversion_value: bookingData.service_price,
      booking_status: 'completed',
    });
  }

  public trackBookingAbandon(bookingData: any): void {
    this.trackEvent(EventName.BOOKING_ABANDON, EventCategory.BOOKING, {
      ...bookingData,
      booking_status: 'abandoned',
      abandon_step: bookingData.step || 'unknown',
    });
  }

  public trackPageView(pageName: string): void {
    this.trackEvent(EventName.PAGE_VIEW, EventCategory.NAVIGATION, {
      page_name: pageName,
      page_type: this.getPageType(pageName),
    });
  }

  public trackButtonClick(buttonId: string, buttonText: string): void {
    this.trackEvent(EventName.BUTTON_CLICK, EventCategory.ENGAGEMENT, {
      button_id: buttonId,
      button_text: buttonText,
      button_location: window.location.pathname,
    });
  }

  public trackFormStart(formId: string): void {
    this.trackEvent(EventName.FORM_START, EventCategory.ENGAGEMENT, {
      form_id: formId,
      form_location: window.location.pathname,
    });
  }

  public trackFormComplete(formId: string, formData: any): void {
    this.trackEvent(EventName.FORM_COMPLETE, EventCategory.ENGAGEMENT, {
      form_id: formId,
      form_location: window.location.pathname,
      form_fields_completed: Object.keys(formData).length,
    });
  }

  public trackError(error: Error, context: any = {}): void {
    this.trackEvent(EventName.ERROR_OCCUR, EventCategory.ERROR, {
      error_message: error.message,
      error_stack: error.stack,
      error_context: context,
      page_location: window.location.pathname,
    });
  }

  private getPageType(pageName: string): string {
    if (pageName.toLowerCase().includes('service')) return 'service';
    if (pageName.toLowerCase().includes('booking')) return 'booking';
    if (pageName.toLowerCase().includes('home')) return 'home';
    if (pageName.toLowerCase().includes('contact')) return 'contact';
    return 'other';
  }
}

export default new AnalyticsService();
