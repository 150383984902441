import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import PayPalButton from '../components/PayPalButton';
import { toast } from 'react-toastify';

export default function PaymentPage() {
  const navigate = useNavigate();
  const { cart, selectedPackage, addOns } = useCart();

  // Calculate total amount
  const calculateTotal = () => {
    let total = selectedPackage?.price || 0;
    if (addOns && addOns.length > 0) {
      total += addOns.reduce((sum, addon) => sum + (addon.price || 0), 0);
    }
    // Round to 2 decimal places to avoid floating point precision issues
    return Math.round(total * 100) / 100;
  };

  const total = calculateTotal();

  // Redirect if cart is empty
  React.useEffect(() => {
    if (!selectedPackage && (!addOns || addOns.length === 0)) {
      toast.error("Your cart is empty. Please add services before proceeding to payment.");
      navigate('/services');
    }
  }, [selectedPackage, addOns, navigate]);

  if (!selectedPackage && (!addOns || addOns.length === 0)) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 text-center mb-8">
            Complete Your Payment
          </h1>

          {/* Order Summary */}
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Order Summary</h2>
            {selectedPackage && (
              <div className="flex justify-between py-2">
                <span>{selectedPackage.name}</span>
                <span>${selectedPackage.price.toFixed(2)}</span>
              </div>
            )}
            {addOns && addOns.map((addon) => (
              <div key={addon.id} className="flex justify-between py-2">
                <span>{addon.name}</span>
                <span>${addon.price.toFixed(2)}</span>
              </div>
            ))}
            <div className="border-t border-gray-200 mt-4 pt-4">
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>${total.toFixed(2)}</span>
              </div>
            </div>
          </div>

          {/* Payment Section */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Payment Method
            </h2>
            <PayPalButton
              amount={total}
              onSuccess={(details) => {
                console.log('Payment successful:', details);
                toast.success('Payment completed successfully!');
              }}
              onError={(error) => {
                console.error('Payment error:', error);
                toast.error('There was an error processing your payment. Please try again.');
              }}
            />
            <p className="text-sm text-gray-500 mt-4 text-center">
              Need to discuss alternative payment options?{' '}
              <a href="/contact" className="text-primary-600 hover:text-primary-500">
                Contact us
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
