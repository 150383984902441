import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, setLogLevel } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB1oz-SRYutek8XKnfw9zqo4afEcHkWEr8",
  authDomain: "cars-85897.firebaseapp.com",
  projectId: "cars-85897",
  storageBucket: "cars-85897.appspot.com",
  messagingSenderId: "505582475178",
  appId: "1:505582475178:web:a5c7c8ebb9c56646a47dd3",
  measurementId: "G-GG7M35L3K8",
  databaseURL: "https://cars-85897-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);

// Enable Firestore debugging
setLogLevel('debug');

export default app;
