import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
          <p className="text-gray-600 mb-8">Effective Date: November 24, 2024</p>
          
          <div className="prose prose-lg max-w-none">
            <p className="text-gray-600 mb-8">
              At Colorado Automotive Report & Service LLC, safeguarding your privacy is our priority. This Privacy Policy outlines how we collect, use, and protect your information when you engage with our services, website, or mobile application. By accessing or using our services, you agree to the practices described in this policy.
            </p>
            
            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">1. Information We Collect</h2>
            <p className="mb-4">We collect various types of information to provide and improve our services. This includes:</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Personal Information</h3>
            <p className="mb-4">Name, contact details (email, phone number), billing address, and payment information.</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Vehicle Information</h3>
            <p className="mb-4">Make, model, Vehicle Identification Number (VIN), and details related to inspection results.</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Location Data</h3>
            <p className="mb-4">GPS data to identify inspection sites and optimize scheduling.</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Device and Usage Information</h3>
            <p className="mb-4">Device type, operating system, IP address, browser type, and user interactions with our website and mobile app.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">2. How We Use Your Information</h2>
            <p className="mb-4">We use the information collected to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Schedule, manage, and perform vehicle inspections</li>
              <li>Deliver comprehensive reports, updates, and notifications</li>
              <li>Process payments, refunds, and maintain transaction records</li>
              <li>Enhance user experiences and recommend relevant services</li>
              <li>Conduct analytics to improve our website, app, and overall service quality</li>
              <li>Comply with legal obligations and enforce our terms of service</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">3. Sharing Your Information</h2>
            <p className="mb-4">Your information is not sold to third parties. We only share your data in specific situations:</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">With Service Providers</h3>
            <p className="mb-4">Third-party vendors assisting with inspections, payment processing, or other operational needs.</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">With Business Partners</h3>
            <p className="mb-4">Trusted partners involved in fulfilling your inspection requests.</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">With Legal Authorities</h3>
            <p className="mb-4">When required by law or to protect our company's rights, safety, and operations.</p>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-2">With Your Consent</h3>
            <p className="mb-4">If you explicitly agree to share your information for particular purposes.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">4. Data Security</h2>
            <p className="mb-4">We implement stringent measures to secure your personal data against unauthorized access, alteration, or disclosure. While we strive to protect your information, no system can guarantee complete security.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">5. Your Rights</h2>
            <p className="mb-4">You have the following rights concerning your personal data:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Access and Correction: View and update the personal data we store</li>
              <li>Deletion: Request the deletion of your information, subject to legal obligations</li>
              <li>Consent Withdrawal: Revoke any previously granted permissions</li>
              <li>Complaint Filing: Report concerns to applicable data protection authorities</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">6. Cookies and Tracking Technologies</h2>
            <p className="mb-4">Our website and app use cookies to enhance functionality, analyze user behavior, and personalize content. You can control cookie preferences through your browser settings.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">7. Links to Third-Party Websites</h2>
            <p className="mb-4">Our services may contain links to external websites. We are not responsible for the privacy practices or content of these third-party sites.</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">8. Policy Updates</h2>
            <p className="mb-4">We may periodically update this Privacy Policy to reflect changes in our practices or legal requirements. Updates will be posted on this page, with the revised "Effective Date."</p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">9. Contact Us</h2>
            <p className="mb-4">For questions or concerns regarding this Privacy Policy, please reach out to us:</p>
            <p className="mb-4">
              Colorado Automotive Report & Service LLC<br />
              Email: afox@autoreportserv.com<br />
              Phone: 303-908-9815
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
